import React from 'react';
import { Link } from 'react-router-dom';
import { DASHBOARD } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        23th Nov 2024
 * @description UI Implementation of Breadcrumb
**/

const Breadcrumb = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumb-area">
      <ol className="breadcrumb">
        <li className="item fw-normal text-muted fs-4">
          <Link to={DASHBOARD}>
            <i className="ti ti-home" style={{ fontSize: '20px' }}></i>
          </Link>
          <i className="ti ti-minus-vertical" style={{ fontSize: 'larger', fontWeight: 'bolder', verticalAlign: 'text-top', display: 'inline-block' }}></i>
        </li>

        {breadcrumbs.map((breadcrumb, index) => (
          <li className="item fw-normal text-muted fs-4" key={index}>
            {(breadcrumb?.link) ? (
              <Link to={breadcrumb?.link}>{breadcrumb?.name}</Link>
            ) : (
              breadcrumb?.name
            )}
            {index < breadcrumbs?.length - 1 && <i className="ti ti-minus-vertical" style={{ fontSize: 'larger', fontWeight: 'bolder', verticalAlign: 'text-top', display: 'inline-block' }}></i>}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Breadcrumb;
