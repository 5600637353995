import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import * as SocialPlatformActions from './socialPlatformActions';
import Modal from '../social-platform-modal';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        22 Nov 2024
 * @description UI Implementation of Social-Platform
 * @param
 * @response 
**/

const SocialPlatform = (props) => {
  const { socialPlatform, actions } = props;
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [socialInfo, setSocialInfo] = useState('');
  const [socialplatform, setsocialplatform] = useState('');
  const [activeStatus, setActiveStatus] = useState({});

  useEffect(() => {
    if (firstInit) {
      actions.socialplatformlistingRequest({ page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof socialPlatform?.data !== 'undefined' && !socialPlatform?.issociallist) {
      setsocialplatform(socialPlatform?.data?.data);
    }
  }, [socialPlatform?.data, socialPlatform?.issociallist]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  // Close Image modal function
  const handleImageModalClose = () => {
    setIsImageModalOpen(false);
  };

  const handleAssignClick = (data) => {
    setSocialInfo(data);
    setIsImageModalOpen(true);
  };

  const handleUpdateStatus = (row) => {
    const currentStatus = activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.social_platform_tbl_status; // Get the current status
    const newStatus = !currentStatus;
    setActiveStatus(prevState => ({
      ...prevState,
      [row.id]: newStatus,
    }));
    actions.updateSocialPlatformStatusRequest({ social_platform_id: row.id, status: newStatus });
  }

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '10px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px'
      },
    }
  };

  const columns = [
    {
      name: 'Platform',
      selector: (row) => row?.social_platform_tbl_logo || "-",
      cell: (row) => (
        <div className="social-logo">
          <img
            src={row?.social_platform_tbl_logo || "../assets/images/placeholderImage/50x50.svg"}
            alt={row?.social_platform_tbl_name}
            width={60}
            height={60}
            onError={(e) => e.target.src = "../assets/images/placeholderImage/50x50.svg"}
          />
          <span className='social-name'>{row?.social_platform_tbl_name || "-"}</span>
        </div>
      ),
    },
    {
      name: "Status",
      minWidth: '50px',
      maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <>
          <div className="form-check text-primary form-switch">
            <input
              className={` ${styles.iconStyle} ${'form-check-input'}`}
              type="checkbox"
              title='Update Status'
              id={`flexSwitchCheckDefault-${row.id}`}
              checked={activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.social_platform_tbl_status}
              onChange={() => handleUpdateStatus(row, activeStatus[row.id])}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        className="card-header text-bg-primary mb-3 d-flex justify-content-between align-items-center"
        style={{ paddingTop: '7px', paddingBottom: '7px' }}
      >
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Social Platform</h3>
        <button
          className="btn btn-outline-light"
          onClick={() => handleAssignClick()}
        >
          Add
        </button>
      </div>
      <div className='social-wrapper'>
        <DataTable
          columns={columns}
          data={socialplatform}
          theme="solarized"
          fixedHeader
          pagination
          paginationServer
          paginationTotalRows={socialPlatform?.data?.total}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageChange}
          responsive
          customStyles={customStyles}
          progressPending={socialPlatform?.issociallist}
        />
      </div>
      {isImageModalOpen && <Modal onClose={handleImageModalClose} socialInfo={socialInfo} />}
    </>
  )
}
const mapStateToProps = (state) => ({
  socialPlatform: state.socialPlatform,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(SocialPlatformActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialPlatform);