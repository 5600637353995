import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { validationRegex } from '../../utils/conts';
import styles from './styles.module.scss';
import { NotificationContainer } from '../notificationContainer';
import { notification } from '../notificationContainer/notification';
import * as SocialPlatformActions from '../social-platform/socialPlatformActions';

/**
 * @author      Nandani.V.Patel
 * @date        22 th Nov 2024
 * @description UI Implementation of Social-Platform Modal
 * @param
 * @response 
**/

const SocialPlatformModal = ({ onClose }) => {
  const { handleSubmit, register, resetField, watch, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const socialplatform = useSelector((state) => state?.socialPlatform);
  const [imagePreview, setImagePreview] = useState(null);

  const watchedImage = watch("image");

  useEffect(() => {
    if (socialplatform?.adddata !== undefined && socialplatform?.adddata?.status === 'success' && !socialplatform.isadd) {
      notification(socialplatform?.adddata?.message, 'success');
      resetField('name');
      resetField('image');
      setImagePreview(null);
      dispatch(SocialPlatformActions.socialplatformlistingRequest({ page: 1, per_page: 10 }));
      dispatch({ type: SocialPlatformActions.ActionTypes.ADD_SOCIAL_PLATFORM_SUCCESS, adddata: undefined });
    }
    if (socialplatform?.adddata === undefined && socialplatform.error !== undefined) {
      notification(socialplatform?.error?.message, 'error');
    }
    dispatch({ type: SocialPlatformActions.ActionTypes.ADD_SOCIAL_PLATFORM_FAILURE, error: undefined });
  }, [socialplatform?.adddata, socialplatform.isadd, socialplatform.error]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("logo", data?.image[0]);
    formData.append("name", data?.name);
    dispatch(SocialPlatformActions.addSocialPlatformRequest(formData));
  };

  useEffect(() => {
    if (watchedImage?.[0]) {
      const file = watchedImage[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];

      if (validTypes.includes(file.type) && file.size <= 2 * 1024 * 1024) {
        setImagePreview(URL.createObjectURL(file));
      } else {
        setImagePreview(null);
      }
    }
  }, [watchedImage]);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="addSocial"
        tabIndex={-1}
        aria-labelledby="addSocialLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addSocialLabel">
                Add Social Platform
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="details-form-content pt-0 pb-0 mb-0">
                <div className="form-content" style={{ padding: '10px' }}>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group" style={{ cursor: 'pointer' }}>
                        <input
                          type="file"
                          accept=".jpeg,.png,.jpg,.webp"
                          {...register("image", {
                            required: "Image is required.",
                            validate: {
                              fileType: (value) => {
                                const file = value?.[0];
                                const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
                                if (file && !validTypes.includes(file.type)) {
                                  return "Invalid file type. Only JPEG, PNG, JPG, and WEBP are allowed.";
                                }
                              },
                              fileSize: (value) => {
                                const file = value?.[0];
                                if (file && file.size > 2 * 1024 * 1024) {
                                  return "File size exceeds 2MB. Please upload a smaller image.";
                                }
                              },
                            },
                          })}
                          className="form-control d-none"
                          id="fileInput"
                        />
                        <label
                          htmlFor="fileInput"
                          className={styles.imagelabel}
                          style={{ border: "1px dashed #ccc" }}
                        >
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              alt="Preview"
                              className={styles.image}
                            />
                          ) : (
                            "Upload Logo"
                          )}
                        </label>
                      </div>
                      {errors?.image && <span className={styles.errormsg}>{errors.image.message}</span>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="controls">
                        <div className='form-floating'>
                          <input
                            type="text"
                            {...register("name", {
                              required: 'Name is required.',
                              validate: {
                                hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name cannot contain numbers.",
                                hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name cannot contain special characters.",
                              },
                              minLength: {
                                value: 2,
                                message: 'Name too short.',
                              },
                              maxLength: {
                                value: 50,
                                message: 'Name too long.',
                              },
                            })}
                            id="name"
                            className="form-control"
                            placeholder="Platform Name"
                            max={50}
                          />
                          <label htmlFor='name' className="form-label fw-semibold text-dark fs-4">Platform Name</label>
                        </div>
                        {errors?.name && (
                          <span className={styles.errormsg}>{errors.name.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div >
      <div className="modal-backdrop fade show" onClick={onClose}></div>
      <NotificationContainer />
    </>
  )
}
export default SocialPlatformModal;