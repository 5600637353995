// list of regex used for validation
export const validationRegex = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  nameHasNoNum: /^[^\d]*$/,
  nameHasNoSpclChar: /^[a-zA-Z\s]*$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,16}$/,
  phoneNum: /^\d{10}$/,
  aadharNum: /^\d{12}$/,
  panCardNum: /^[A-Z]{5}\d{4}[A-Z]{1}$/,
  url: /^(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
};

export const defaultErrorMsg = "Something went wrong. Please try again.";