import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import * as ReviewsiteActions from '../reviewsitedetails/reviewsitedetailsActions';
import { REVIEWSITES } from '../../global/routes';
import Breadcrumb from '../breadcrumbs';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        13 Nov 2024
 * @description Review-site Company user
 * @param
 * @response 
**/

const ReviewsiteCompanyUser = () => {
  const reviewsitedetails = useSelector((state) => state?.reviewsitedetails);
  const dispatch = useDispatch();

  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [reviewsitesuserdetail, setReviewsiteuserdetail] = useState('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reviewsite = queryParams.get('reviewsite');
  const reviewsiteId = queryParams.get('id');
  const companyId = queryParams.get('companyid');

  const breadcrumbs = [
    { name: 'Review Sites', link: REVIEWSITES },
    { name: 'Company List', link: `${REVIEWSITES}?reviewsite=${reviewsite}&id=${reviewsiteId}` },
    { name: 'User Details', link: "" },
  ];

  useEffect(() => {
    if (firstInit) {
      dispatch(ReviewsiteActions.reviewsitewiseuserRequest({ company_id: companyId, page, per_page: perPage, review_site_id: reviewsiteId }));
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '10px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px',
      },
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: row => `${row?.user_first_name} ${row?.user_last_name}`,
      sortable: true,
      maxWidth: '250px',
    },
    {
      name: 'Review Content',
      selector: row => row?.reviewContent || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      cell: (row) => <div className="custom-cell">{row?.reviewContent || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}</div>,
      // width: '55%',
    },
    {
      name: 'Date Of Review',
      selector: row => new Date(row?.date_of_review).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      }),
      maxWidth: '160px',
    },
    {
      name: 'Status',
      selector: row => row?.user_reviews_ai_check_status_name || '-',
      cell: row => {
        const renderStatusBadge = (status) => {
          switch (status) {
            case 'Approved':
              return <span
                className={`${'status - btn'} ${styles.btnactive}`}
                data-review-status="approved"
              >
                Approved
              </span>
            case 'Rejected':
              return (
                <span
                  className={`${'status - btn'} ${styles.btnInactive}`}
                  data-review-status="rejected"
                >
                  Rejected
                </span>
              );
            case 'Pending':
              return (
                <span
                  className="btn bg-primary-subtle text-primary"
                  data-review-status="Pending"
                >
                  Pending
                </span>
              );
            default:
              return <span
                className={`${'status - btn'} ${styles.btnunknow}`}
                data-review-status="rejected"
              >
                Unknown
              </span>
          }
        };
        return renderStatusBadge(row?.user_reviews_ai_check_status_name);
      },
      maxWidth: '160px',
      right: "yes",
    },
  ];

  useEffect(() => {
    if (typeof reviewsitedetails?.userdata !== 'undefined' && !reviewsitedetails?.isCompanyUserReview) {
      setReviewsiteuserdetail(reviewsitedetails?.userdata?.data);
    }
    dispatch({ type: ReviewsiteActions.ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_SUCCESS, userdata: undefined });
  }, [reviewsitedetails?.userdata, reviewsitedetails?.isCompanyUserReview]);

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`${'card-header text-bg-primary mb-3 mt-2'} ${styles.headingStyle}`}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>User Details</h3>
      </div>
      <DataTable
        columns={columns}
        data={reviewsitesuserdetail}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={reviewsitedetails?.userdata?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        progressPending={reviewsitedetails.isCompanyUserReview}
      />
    </>
  )
}

export default ReviewsiteCompanyUser;