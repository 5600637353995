import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import * as ReviewlistingActions from './ReviewSitesActions';
import styles from './styles.module.scss';
import { REVIEWSITES } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description ReviewSites listing
 * @param
 * @response 
**/

const ReviewSites = (props) => {
  const { reviewsite, actions } = props;
  const navigate = useNavigate();
  const [firstInit, setFirstInit] = useState(false);
  const [reviewsites, setReviewsites] = useState('');

  useEffect(() => {
    if (firstInit) {
      actions.reviewsitelistingRequest({ page: 1, per_page: 16 });
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (typeof reviewsite?.data !== 'undefined' && !reviewsite?.isReviewSiteListing) {
      setReviewsites(reviewsite?.data?.data);
    }
  }, [reviewsite?.data, reviewsite?.isReviewSiteListing]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '20px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px'
      },
    }
  };

  const columns = [
    {
      name: 'Review site',
      selector: row => row?.review_site_tbl_name,
      cell: (row) => (
        <>
            <img
              src={row?.review_site_logo || ""}
              alt={row?.review_site_tbl_name || ""}
              width="40px"
              height="40px"
            />
            <span className='ps-3'>{row?.review_site_tbl_name || "-"}</span>
          </>
      ),
      sortable: true,
    },
    {
      name: 'Per Review Price',
      selector: row => `$ ${row?.review_site_price || 0}`,
      sortable: true,
    },
    {
      name: 'Employee Earning',
      selector: row => `$ ${row?.employee_earnings || 0}`,
      sortable: true,
    },
    {
      name: 'User Earning',
      selector: row => `$ ${row?.review_site_tbl_user_review_earning || 0}`,
      sortable: true,
    },
    {
      name: 'Team Leader Commission',
      selector: row => `$ ${row?.teamleader_commission || 0}`,
      sortable: true,
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <>
          <div
            title='View Companies'
            className={`action-btn blue-action-btn`}
            onClick={() => {
              const encodedName = encodeURIComponent(row?.review_site_tbl_name); // Encode the name for URL
              navigate(`${REVIEWSITES}?reviewsite=${encodedName}&id=${row?.id}`); // Construct the URL
            }}
          >
            <i className="ti ti-eye fs-5" />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>
          {"Review Sites (with per review price & earnings)"}
        </h3>
      </div>
      <DataTable
        columns={columns}
        data={reviewsites}
        theme="solarized"
        fixedHeader
        responsive
        customStyles={customStyles}
        progressPending={reviewsite?.isReviewSiteListing}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  reviewsite: state.reviewsite,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ReviewlistingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSites);