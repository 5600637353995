import {
  DASHBOARD,
  USER,
  TEAMLEADER,
  COMPANY,
  PLANCREATION,
  REVIEWSITES,
  WITHDRAWAL,
  ASSIGNTARGET,
  EMPLOYEE,
  SOCIAL_PLATFORM
} from "../../global/routes";

export const navigationData = [
  {
    title: 'Dashboard',
    href: DASHBOARD,
    icon: 'ti ti-layout-dashboard',
  },
  {
    title: 'Users',
    href: USER,
    icon: 'ti ti-users',
  },
  {
    title: 'Team Leader',
    href: TEAMLEADER,
    icon: 'ti ti-user',
  },
  {
    title: 'Company Details',
    href: COMPANY,
    icon: 'ti ti-align-box-left-middle',
  },
  {
    title: 'Plan Creation',
    href: PLANCREATION,
    icon: 'ti ti-businessplan',
  },
  {
    title: 'Review Sites',
    href: REVIEWSITES,
    icon: 'ti ti-view-360',
  },
  {
    title: 'Withdrawal Request',
    href: WITHDRAWAL,
    icon: 'ti ti-credit-card',
  },
  {
    title: 'Assign Target to TL',
    href: ASSIGNTARGET,
    icon: 'ti ti-target',
  },
  {
    title: 'Employee',
    href: EMPLOYEE,
    icon: 'ti ti-notes',
  },
  {
    title: 'Social Platform',
    href: SOCIAL_PLATFORM,
    icon: 'ti ti-social',
  },
  {
    title: 'Logout',
    href: null,
    icon: 'ti ti-logout',
    action: 'logout',
  }
]