import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from './userActions';
import DataTable from 'react-data-table-component';
import styles from './styles.module.scss'

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description UI for  User
 * @param
 * @response      
**/

const User = (props) => {
  const { users, actions } = props;
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [user, setuser] = useState('');
  const [activeStatus, setActiveStatus] = useState({});

  useEffect(() => {
    if (firstInit) {
      actions.getuserRequest({ page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof users?.data !== 'undefined' && !users?.isUser) {
      setuser(users?.data?.data);
    }
  }, [users?.data, users?.isUser]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handleUpdateStatus = (row) => {
    const currentStatus = activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.user_status;
    const newStatus = !currentStatus;

    setActiveStatus(prevState => ({
      ...prevState,
      [row.id]: newStatus,
    }));
    actions.updateUserStatusRequest({ user_id: row.id, status: newStatus });
  }

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '10px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px',
      },
    }
  };

  const columns = [
    {
      name: 'First Name',
      selector: row => row.user_first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.user_last_name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.user_email,
      sortable: true,
    },
    {
      name: 'Phone number',
      selector: row => row.user_phone_number || '-',
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <>
          <div className="form-check py-2 form-switch">
            <input
              className={` ${styles.iconStyle} ${'form-check-input'}`}
              type="checkbox"
              title='Update Status'
              id={`flexSwitchCheckDefault-${row.id}`}
              checked={activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.user_status}
              onChange={() => handleUpdateStatus(row, activeStatus[row.id])}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Users</h3>
      </div>
      <DataTable
        columns={columns}
        data={user}
        className={styles.dataTable}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={users?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        progressPending={users?.isUser}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);


