import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form'
import { deletereviewsitediscountRequest, planCreationRequest } from './planCreationActions';

const Discount = ({ propState, styles }) => {
  const dispatch = useDispatch();
  const [checkedFields, setCheckedFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const {
    control,
    register: registerDiscounts,
    reset,
    formState: { errors: errorsDiscounts },
    getValues
  } = useForm({
    defaultValues: {
      discounts: [{ minValue: '', maxValue: '', discountPrice: '' }]
    },
    mode: 'onChange'
  });

  useEffect(() => {
    if (propState?.reviewsitediscount?.status === 'success') {
      const discountData = propState.reviewsitediscount.data.length
        ? propState.reviewsitediscount.data.map(item => ({
          minValue: item.review_site_discount_min_value,
          maxValue: item.review_site_discount_max_value,
          discountPrice: item.review_site_discount_perecentage,
        }))
        : [{ minValue: '', maxValue: '', discountPrice: '' }]; // Default field if no data

      reset({ discounts: discountData });
    }
  }, [propState?.reviewsitediscount]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  });

  const handleCheck = (index) => {
    const discountData = getValues(`discounts.${index}`);
    const newErrorMessages = {};

    // Validate required fields
    if (!discountData.minValue) {
      newErrorMessages.minValue = 'Min Count is required.';
    }
    if (!discountData.maxValue) {
      newErrorMessages.maxValue = 'Max Count is required.';
    }
    if (!discountData.discountPrice) {
      newErrorMessages.discountPrice = 'Discount Percentage is required.';
    }

    setErrorMessages(prev => ({
      ...prev,
      [index]: newErrorMessages
    }));

    // If there are no errors, call the API
    if (Object.keys(newErrorMessages).length === 0) {
      setCheckedFields(prev => {
        const updated = [...prev];
        updated[index] = true; // Mark the current field as checked
        return updated;
      });

      const payload = {
        reviewPercentage: parseFloat(discountData.discountPrice),
        minReviews: parseInt(discountData.minValue, 10),
        maxReviews: parseInt(discountData.maxValue, 10),
      };
      dispatch(planCreationRequest(payload));
    }
  };

  const isRemoveDisabled = (index) => {
    const discount = getValues(`discounts.${index}`);
    const isFieldEmpty = !discount?.minValue && !discount?.maxValue && !discount?.discountPrice;
    return fields?.length === 1 && isFieldEmpty;
  };

  return (
    <>
      <div className="card-header text-bg-primary my-3 mt-4">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Discount</h3>
      </div>
      <div className="row">
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <div className="col-lg-4 col-md-3">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Min Count"
                  {...registerDiscounts(`discounts.${index}.minValue`, {
                    required: true
                  })}
                />
                <label className='fs-4 text-dark'>Min Count</label>
                {errorMessages[index]?.minValue && (
                  <span className={styles.errormsg}>{errorMessages[index].minValue}</span>
                )}
                {errorsDiscounts?.discounts?.[index]?.minValue && (
                  <span className={styles.errormsg}>{errorsDiscounts.discounts[index].minValue.message}</span>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-3">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max Count"
                  {...registerDiscounts(`discounts.${index}.maxValue`, {
                    required: true
                  })}
                />
                <label className='fs-4 text-dark'>Max Count</label>
                {errorMessages[index]?.maxValue && (
                  <span className={styles.errormsg}>{errorMessages[index].maxValue}</span>
                )}
                {errorsDiscounts?.discounts?.[index]?.maxValue && (
                  <span className={styles.errormsg}>{errorsDiscounts.discounts[index].maxValue.message}</span>
                )}
              </div>
            </div>
            <div className="col-lg-2 col-md-3">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Discount Price"
                  {...registerDiscounts(`discounts.${index}.discountPrice`, {
                    required: true
                  })}
                />
                <label className='fs-4 text-dark'>%</label>
                {errorMessages[index]?.discountPrice && (
                  <span className={styles.errormsg}>{errorMessages[index].discountPrice}</span>
                )}
                {errorsDiscounts?.discounts?.[index]?.discountPrice && (
                  <span className={styles.errormsg}>{errorsDiscounts.discounts[index].discountPrice.message}</span>
                )}
              </div>
            </div>
            <div className="col-lg-2 col-md-3 gap-md-2 d-flex justify-content-between align-items-center mb-3 p-1">
              <button
                type="button"
                title='Save'
                className="btn save-discount btn-success"
                onClick={() => handleCheck(index)}
                disabled={propState?.isPlancreation}
              >
                {propState?.isPlancreation ? (
                  <div className='spinner-border spinner-border-sm'></div>
                ) : (
                  <i className={`ti ti-check ${styles.iconstyle}`}></i>
                )}
              </button>
              <button
                type="button"
                title='Add'
                className="btn btn-primary"
                onClick={() => append({ minValue: '', maxValue: '', discountPrice: '' })}
                // style={{ height: "45px" }}
              >
                <i className={`ti ti-plus ${styles.iconstyle}`}></i>
              </button>
              <button
                type="button"
                title='Delete'
                className="btn btn-danger"
                onClick={() => {
                  const discountData = propState.reviewsitediscount.data[index]; // Get the discount data by index
                  if (discountData) {
                    const discountId = discountData?.id; // Fetch the discount ID
                    dispatch(deletereviewsitediscountRequest({ id: discountId })); // Pass the ID to the delete action
                  }
                  remove(index); // Remove the field from the form
                }}
                disabled={propState?.isdeletediscount || isRemoveDisabled(index)}
                // style={{ height: "45px" }}
              >
                {propState?.isdeletediscount ? (
                  <div className='spinner-border spinner-border-sm'></div>
                ) : (
                  <i className={`ti ti-minus ${styles.iconstyle}`}></i>
                )}
              </button>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default Discount;