export const pwdTooltipContent = () => (
  <div className="tooltiptext">
    <ul className='m-0 ps-2'>
      <li>Must be at least 8 characters long</li>
      <li>Cannot exceed 64 characters</li>
      <li>Must contain at least one uppercase letter (A-Z)</li>
      <li>Must contain at least one lowercase letter (a-z)</li>
      <li>Must contain at least one digit (0-9)</li>
      <li>Must contain at least one special character</li>
      <li>Cannot contain whitespace</li>
    </ul>
  </div>
);