import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ASSIGNTARGET } from '../../../global/routes';
import Breadcrumb from '../../breadcrumbs/index';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

/**
 * @author      Nandani.V.Patel
 * @date        19 Nov 2024
 * @description Company Assign-Target Purchased Site listing
 * @param
 * @response 
**/

const PurchasedSites = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company_name = queryParams.get('name'); // For query parameter
  const company_id = queryParams.get('id');

  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const assignTargetState = useSelector((state) => state.assignTarget);

  const breadcrumbs = [
    { name: 'Assign Target', link: ASSIGNTARGET },
    { name: company_name, link: "" },
  ];

  const staticPurchaseSiteData = [
    {
      id: '1',
      site_name: 'Foursquare',
      purchase_count: '1',
      assign_count: '5',
    },
    {
      id: '2',
      site_name: 'Capterra',
      purchase_count: '3',
      assign_count: '7',
    },
    {
      id: '3',
      site_name: 'App Store',
      purchase_count: '5',
      assign_count: '9',
    },
    {
      id: '4',
      site_name: 'Trustpilot',
      purchase_count: '2',
      assign_count: '3',
    },
    {
      id: '5',
      site_name: 'Google Maps',
      purchase_count: '6',
      assign_count: '10',
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '10px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px'
      },
    }
  };

  const columns = [
    {
      name: 'Site Name',
      selector: row => row?.site_name || '-',
      sortable: true,
    },
    {
      name: 'Purchased Count',
      selector: row => row?.purchase_count || '-',
    },
    {
      name: 'Assigned Count',
      selector: row => row?.assign_count || '-',
    },
    {
      name: "Action",
      center: 'yes',
      cell: (row) => (
        <>
          <div className="form-check form-switch">
            <button
              type="button"
              title='Assign'
              className='btn btn-primary d-flex align-items-center'
              data-review-status="assign"
              onClick={() => {
                const encodedName = encodeURIComponent(company_name);
                navigate(`${ASSIGNTARGET}?name=${encodedName}&id=${company_id}&siteid=${row.id}`);
              }}
            >
              Assign
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`${'card-header text-bg-primary mb-3 mt-2'} ${styles.headingStyle}`}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>{company_name}</h3>
      </div>
      <DataTable
        columns={columns}
        data={staticPurchaseSiteData}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        // paginationTotalRows={withdrawal?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        progressPending={assignTargetState?.isCompanySite}
      />
    </>
  )
}

export default PurchasedSites;