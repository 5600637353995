import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import * as ReviewDetailsActions from './reviewsitedetailsActions';
import { REVIEWSITES } from '../../global/routes';
import { NotificationContainer } from '../notificationContainer';
import { notification } from '../notificationContainer/notification';
import Breadcrumb from '../breadcrumbs';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description UI for Reviewsite details.
 * @param
 * @response      
**/

const ReviewsiteDetails = (props) => {
  const { reviewsitedetails, actions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstInit, setFirstInit] = useState(false);
  const [reviewsitesdetail, setReviewsitesdetail] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const location = useLocation();
  // const reviewsite_id = location.id ? location.id : '';
  // const [companyName, companyId] = location.id ? location.id.split('=') : [null, null];
  // const reviewsite_id = companyId ? parseInt(companyId, 10) : null;
  const queryParams = new URLSearchParams(location.search);
  const reviewsiteName = queryParams.get('reviewsite'); // For query parameter
  const reviewsite_id = queryParams.get('id');

  const breadcrumbs = [
    { name: 'Review Sites', link: REVIEWSITES },
    { name: reviewsiteName, link: "" },
  ];

  useEffect(() => {
    if (firstInit) {
      actions.reviewsitedetailsRequest({ review_site_id: reviewsite_id, page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof reviewsitedetails?.data !== 'undefined' && !reviewsitedetails?.isReviewSiteDetail) {
      setReviewsitesdetail(reviewsitedetails?.data?.data);
    }
    dispatch({ type: ReviewDetailsActions.ActionTypes.REVIEWSITES_DETAILS_SUCCESS, data: undefined });
  }, [reviewsitedetails?.data, reviewsitedetails?.isReviewSiteDetail]);

  useEffect(() => {
    if (Array?.isArray(reviewsitedetails?.error?.data) && reviewsitedetails?.error?.status === 'failed') {
      notification(reviewsitedetails?.error?.message, 'error');
      dispatch({ type: ReviewDetailsActions.ActionTypes.REVIEWSITES_DETAILS_FAILURE, error: undefined });
    }
    dispatch({ type: ReviewDetailsActions.ActionTypes.REVIEWSITES_DETAILS_FAILURE, error: undefined });
  }, [reviewsitedetails?.error]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '10px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px',
      },
    }
  };

  const columns = [
    {
      name: 'Company Name',
      selector: row => row?.company_tbl_name,
      sortable: true,
    },
    {
      name: 'Category name',
      selector: row => row?.category_name || '-',
      sortable: true,
    },
    {
      name: 'Location',
      selector: row => row?.country_name || '-',
      minWidth: '200px',
      maxWidth: '80px',
    },
    {
      name: "Action",
      center: 'yes',
      minWidth: '160px',
      maxWidth: '80px',
      cell: (row) => (
        <>
          <div className="form-check">
            <button
              type="button"
              title='View'
              className="btn bg-primary-subtle text-primary "
              onClick={() => {
                const encodedName = encodeURIComponent(row?.review_site_tbl_name);
                navigate(`${REVIEWSITES}?reviewsite=${encodedName}&companyid=${row.id}&id=${row.review_site_id}`);
              }}
            >
              User list
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`${'card-header text-bg-primary mb-3 mt-2'} ${styles.headingStyle}`}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>{reviewsiteName} - Companies</h3>
      </div>
      <DataTable
        columns={columns}
        data={reviewsitesdetail}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={reviewsitedetails?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        progressPending={reviewsitedetails?.isReviewSiteDetail}
      />
      <NotificationContainer />
    </>
  )
}

const mapStateToProps = (state) => ({
  reviewsitedetails: state.reviewsitedetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ReviewDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsiteDetails);