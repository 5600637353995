import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanydetailsActions from '../company/companylistActions';
import { COMPANY } from '../../global/routes';
import { NotificationContainer } from '../notificationContainer';
import { notification } from '../notificationContainer/notification';
import Breadcrumb from '../breadcrumbs';

/**
 * @author      Nandani.V.Patel
 * @date        23 Oct 2024
 * @description UI for Company Details
 * @param
 * @response      
**/

const CompanyDetails = () => {
  const companylist = useSelector((state) => state?.companylist);
  const [companyInfo, setCompanyInfo] = useState({});
  const [firstInit, setFirstInit] = useState(false);
  const location = useParams();
  const dispatch = useDispatch();
  const company_id = location.id ? location.id : '';

  const breadcrumbs = [
    { name: 'Company', link: COMPANY },
    { name: `${companyInfo?.company_tbl_name || "Company"}`, link: "" },
  ];

  useEffect(() => {
    if (firstInit) {
      dispatch(CompanydetailsActions.companydetailsRequest({ company_id: company_id }));
      setFirstInit(false);
    }
  }, [firstInit, dispatch, company_id]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (companylist?.data?.status === 'success') {
      setCompanyInfo(companylist?.data?.data);
    }
    if (companylist?.data === undefined && companylist.error !== undefined) {
      notification(companylist?.error?.message, 'error');
    }
    dispatch({ type: CompanydetailsActions.ActionTypes.COMPANY_DETAILS_FAILURE, error: undefined });
  }, [companylist?.data, companylist?.error]);

  const formatedDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options);
  };

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="d-flex w-100">
        <div className="d-flex w-100">
          <div className="w-100">
            <div className="chat-container h-100 w-100">
              <div className="chat-box-inner-part h-100">
                <div className="chatting-box app-email-chatting-box">
                  <div className="card-header text-bg-primary mb-3 mt-2" style={{ cursor: 'pointer' }}>
                    <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Company Details - {companyInfo?.company_tbl_name}</h3>
                  </div>
                  <div className="position-relative overflow-hidden">
                    <div className="position-relative">
                      <div
                        className="chat-box email-box mh-n100 p-9"
                        data-simplebar="init"
                      >
                        <div
                          className="chat-list chat active-chat"
                          data-user-id={1}
                        >
                          <div className="hstack align-items-start mb-7 pb-1 align-items-center justify-content-between">
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={companyInfo?.company_tbl_logo || 'https://placehold.co/400x300'}
                                onError={(e) => {
                                  e.target.onerror = null; // Prevents infinite fallback loop
                                  e.target.src = 'https://placehold.co/400x300';
                                }}
                                alt="Company Logo"
                                width={100}
                                height={100}
                                className="rounded-circle"
                                style={{ objectFit: 'cover' }}
                              />
                              <div>
                                <h6 className="mb-2 fw-semibold text-dark fs-6">{companyInfo?.company_tbl_name || '-'}</h6>
                                <p className="mb-0 fw-normal text-muted fs-5">{companyInfo?.category_name || '-'}</p>
                                <p className="mb-0 fw-normal text-muted fs-4">{companyInfo?.country_name || '-'}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="pb-2 mb-4" style={{ borderBottom: '2px solid #ebf1f6' }}>
                              <p className="mb-2 fw-semibold text-dark fs-6">Description</p>
                              <p className="mb-3 fw-normal text-muted fs-5">
                                {companyInfo?.company_tbl_description || '-'}
                              </p>
                            </div>
                            <div className="col-4 mb-7">
                              <p className="mb-1 mb-2 fw-semibold text-dark fs-5">Phone number</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4">{companyInfo?.company_tbl_mob_no || '-'}</h6>
                            </div>
                            <div className="col-8 mb-7">
                              <p className="mb-1 fs-3 fw-semibold text-dark fs-5">Contact Email</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4">
                                {companyInfo?.company_contact_email || '-'}
                              </h6>
                            </div>
                            <div className="col-12 mb-9">
                              <p className="mb-1 fs-3 fw-semibold text-dark fs-5">Address</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4">
                                {companyInfo?.company_tbl_addr || '-'}
                              </h6>
                            </div>
                            <div className="col-4 mb-7">
                              <p className="mb-1 fs-3 fw-semibold text-dark fs-5">Pincode</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4"> {companyInfo?.company_pincode || '-'}</h6>
                            </div>
                            <div className="col-8 mb-7">
                              <p className="mb-1 fs-3 fw-semibold text-dark fs-5">Registration Number</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4"> {companyInfo?.company_registration_number || '-'}</h6>
                            </div>
                            <div className="col-4 mb-7">
                              <p className="mb-1 fs-3 fw-semibold text-dark fs-5">Established Date</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4">
                                {formatedDate(companyInfo?.company_tbl_established_date) || '-'}
                              </h6>
                            </div>
                            <div className="col-8 mb-7">
                              <p className="mb-1 fs-3 fw-semibold text-dark fs-5">Website URL</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4"> {companyInfo?.company_tbl_website || '-'}</h6>
                            </div>
                            <div className="col-4 mb-7">
                              <p className="mb-1 fs-3 fw-semibold text-dark fs-5">Contact Person Name</p>
                              <h6 className="mb-0 fw-normal text-muted fs-4"> {companyInfo?.company_contact_person_name || '-'}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default CompanyDetails;
