import { Fragment } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/index';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import {
  SIGNIN,
  NOTFOUND,
  DASHBOARD,
  USER,
  TEAMLEADER,
  COMPANY,
  COMPANYID,
  ORDERS,
  PLANCREATION,
  REVIEWSITES,
  WITHDRAWAL,
  ASSIGNTARGET,
  EMPLOYEE,
  EMPLOYEE_REGISTER,
  SOCIAL_PLATFORM,
  COMPANYCAMPAIGNS
} from './global/routes';

import PrivateRoute from './utils/privateRoute';
import LayoutWrapper from './component/layoutwrapper';
import Login from './component/login';
import Dashboard from './component/dashboard';
import NotFound from './component/notfound';
import User from './component/user';
import TeamLeader from './component/teamLeader';
import Company from './component/company';
import CompanyOrders from './component/companyOrders';
import PlanCreation from './component/planCreation';
import ReviewSites from './component/reviewsites';
import ReviewsiteDetails from './component/reviewsitedetails';
import ReviewsiteCompanyUser from './component/reviewsiteCompanyUser';
import CompanyDetails from './component/companydetails';
import Withdrawal from './component/withdrawal';
import AssignTarget from './component/assign-target';
import EmployeeListing from './component/employee-listing';
import EmployeeRegister from './component/employee-register';
import SocialPlatform from './component/social-platform';
import PurchasedSites from './component/assign-target/purchased-sites';
import TeamleadersList from './component/assign-target/teamleadersList';

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description Rendering React Application component based on Routes.
 * @param
 * @response 
**/

const CompanyUsersWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reviewsitename = queryParams.get('reviewsite');
  const reviewsiteId = queryParams.get('id');
  const companyId = queryParams.get('companyid');

  // Conditionally render components based on query parameters
  if (reviewsitename && companyId && reviewsiteId) {
    return <ReviewsiteCompanyUser reviewsitename={reviewsitename} companyId={companyId} reviewsiteId={reviewsiteId} />;
  } else if (reviewsitename && reviewsiteId) {
    return <ReviewsiteDetails reviewsitename={reviewsitename} reviewsiteId={reviewsiteId} />;
  } else {
    return <ReviewSites />;
  }
};

const AssignTargetWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyname = queryParams.get('name');
  const companyId = queryParams.get('id');
  const siteId = queryParams.get('siteid');

  // Conditionally render components based on query parameters
  if (companyname && companyId && siteId) {
    return <TeamleadersList companyname={companyname} companyId={companyId} siteId={siteId} />;
  } else if (companyname && companyId) {
    return <PurchasedSites companyname={companyname} companyId={companyId} />;
  } else {
    return <AssignTarget />;
  }
};

const App = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path={SIGNIN} element={<Login />} />
            <Route exact element={<PrivateRoute />}>
              <Route element={<LayoutWrapper />}>
                <Route path={DASHBOARD} element={<Dashboard />} />
                <Route path={USER} element={<User />} />
                <Route path={TEAMLEADER} element={<TeamLeader />} />
                <Route path={COMPANY} element={<Company />} />
                <Route path={`${COMPANY}${COMPANYID}`} element={<CompanyDetails />} />
                <Route path={COMPANYCAMPAIGNS} element={<CompanyOrders />} />
                <Route path={PLANCREATION} element={<PlanCreation />} />
                <Route path={REVIEWSITES} element={<CompanyUsersWrapper />} />
                <Route path={WITHDRAWAL} element={<Withdrawal />} />
                <Route path={ASSIGNTARGET} element={<AssignTargetWrapper />} />
                <Route path={EMPLOYEE} element={<EmployeeListing />} />
                <Route path={EMPLOYEE_REGISTER} element={<EmployeeRegister />} />
                <Route path={SOCIAL_PLATFORM} element={<SocialPlatform />} />
              </Route>
            </Route>
            <Route path={NOTFOUND} element={<NotFound />} />
            <Route path="*" element={<Navigate to={NOTFOUND} replace />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    </Fragment>
  )
}
export default App;