import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as DashboardCountActions from '../dashboardCount/dashboardcountActions';
import { isAuth } from '../../utils/auth';
import { SIGNIN } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description User Dashboard.
 * @param
 * @response 
**/

const Dashboard = () => {
  const counts = useSelector((state) => state?.dashboardCounts?.dashboardcount);
  const users = useSelector((state) => state?.dashboardCounts?.dashboarduser);
  const companies = useSelector((state) => state?.dashboardCounts?.dashboardcompany);
  const teamleaders = useSelector((state) => state?.dashboardCounts?.dashboardteamleader);

  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = isAuth();
  useEffect(() => {
    if (!auth) {
      navigate(SIGNIN);
    }
  }, [auth, navigate]);

  useEffect(() => {
    if (firstInit) {
      dispatch(DashboardCountActions.getdashboardcount());
      dispatch(DashboardCountActions.getdashboardusers());
      dispatch(DashboardCountActions.getdashboardcompany());
      dispatch(DashboardCountActions.getdashboardteamleader());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="row g-3">
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-primary-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="icon">
                <i className="ti ti-building-skyscraper" style={{ fontSize: '50px', color: 'var(--theme-blue-color)' }}></i>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 ms-3 fs-6 text-center fw-semibold text-info">Companies</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <h3 className="mb-0 fw-semibold fs-7 text-info text-center">{counts?.companyCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-danger-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="icon">
                <i className="ti ti-search" style={{ fontSize: '50px', color: 'rgb(250, 137, 107)' }}></i>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 ms-3 text-center fw-semibold text-danger">Reviews</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <h3 className="mb-0 fw-semibold fs-7 text-center text-danger">{counts?.reviewCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-success-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="icon">
                <i className="ti ti-users" style={{ fontSize: '50px', color: 'rgb(19, 222, 185)' }}></i>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 ms-3 text-center fw-semibold text-success">Users</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <h3 className="mb-0 fw-semibold fs-7 text-success text-center">{counts?.userCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-warning-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="icon">
                <i className="ti ti-user" style={{ fontSize: '50px', color: 'rgb(255, 174, 31)' }}></i>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 ms-3 text-center fw-semibold text-warning">Team Leader</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <h3 className="mb-0 fw-semibold fs-7 text-center text-warning">{counts?.teamLeaderCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* latest users */}
      <div className="card w-100 position-relative overflow-hidden" style={{ marginTop: '50px' }}>
        <div className="px-4 py-3 border-bottom card-header text-bg-primary">
          <h4 className="card-title mb-0" style={{ color: 'white' }}>Users</h4>
        </div>
        {(typeof users !== 'undefined' && users?.data?.length > 0) ? (
          <div className="card-body p-4">
            <div className="table-responsive mb-4 border rounded-1">
              <table className="table text-nowrap mb-0 align-middle">
                <thead className="table-info">
                  <tr>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">First Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Last Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Email</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Phone No.</h6>
                    </th>
                  </tr>
                </thead>
                {users?.data?.map((user) => (
                  <tr key={user.id}>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_first_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_last_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_email || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_phone_number || '-'}</p></td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Users Not found</h5>
        )}
      </div>
      {/* latest companies */}
      <div className="card w-100 position-relative overflow-hidden" style={{ marginTop: '50px' }}>
        <div className="px-4 py-3 border-bottom card-header text-bg-primary">
          <h4 className="card-title mb-0" style={{ color: 'white' }}>Companies</h4>
        </div>
        {(typeof companies !== 'undefined' && companies?.data?.length > 0) ? (
          <div className="card-body p-4">
            <div className="table-responsive mb-4 border rounded-1">
              <table className="table text-nowrap mb-0 align-middle">
                <thead className="table-info">
                  <tr>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Company Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Category</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Email</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Registration No.</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Country</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Contact No.</h6>
                    </th>
                  </tr>
                </thead>
                {companies?.data?.map((user) => (
                  <tr key={user.id}>
                    <td><p className="mb-0 fw-normal fs-4">{user?.company_tbl_name || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.category_name || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_email || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.company_registration_number || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.country_name || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.company_tbl_mob_no || '-'}</p></td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Companies Not found</h5>
        )}
      </div>
      {/* latest team leaders */}
      <div className="card w-100 position-relative overflow-hidden" style={{ marginTop: '50px' }}>
        <div className="px-4 py-3 border-bottom card-header text-bg-primary">
          <h4 className="card-title mb-0" style={{ color: 'white' }}>Team Leaders</h4>
        </div>
        {(typeof teamleaders !== 'undefined' && teamleaders?.data?.length > 0) ? (
          <div className="card-body p-4">
            <div className="table-responsive mb-4 border rounded-1">
              <table className="table text-nowrap mb-0 align-middle">
                <thead className="table-info">
                  <tr>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">First Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Last Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Email</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Phone No.</h6>
                    </th>
                  </tr>
                </thead>
                {teamleaders?.data?.map((user) => (
                  <tr key={user.id}>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_first_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_last_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_email || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_phone_number || '-'}</p></td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Team leaders Not found</h5>
        )}
      </div>
    </>
  )
}
export default Dashboard;