/**
 * @author      Nandani.V.Patel
 * @date        20 Nov 2024
 * @description ActionTypes and ActionCreator Function for Employee Registration Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',

  GET_EMPLOYEE_LISTING_REQUEST: 'GET_EMPLOYEE_LISTING_REQUEST',
  GET_EMPLOYEE_LISTING_SUCCESS: 'GET_EMPLOYEE_LISTING_SUCCESS',
  GET_EMPLOYEE_LISTING_FAILURE: 'GET_EMPLOYEE_LISTING_FAILURE',

  GET_EMPLOYEE_DETAILS_REQUEST: 'GET_EMPLOYEE_DETAILS_REQUEST',
  GET_EMPLOYEE_DETAILS_SUCCESS: 'GET_EMPLOYEE_DETAILS_SUCCESS',
  GET_EMPLOYEE_DETAILS_FAILURE: 'GET_EMPLOYEE_DETAILS_FAILURE',

  UPDATE_EMPLOYEE_DETAILS_REQUEST: 'UPDATE_EMPLOYEE_DETAILS_REQUEST',
  UPDATE_EMPLOYEE_DETAILS_SUCCESS: 'UPDATE_EMPLOYEE_DETAILS_SUCCESS',
  UPDATE_EMPLOYEE_DETAILS_FAILURE: 'UPDATE_EMPLOYEE_DETAILS_FAILURE',

  UPDATE_EMPLOYEE_STATUS_REQUEST: 'UPDATE_EMPLOYEE_STATUS_REQUEST',
  UPDATE_EMPLOYEE_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_STATUS_SUCCESS',
  UPDATE_EMPLOYEE_STATUS_FAILURE: 'UPDATE_EMPLOYEE_STATUS_FAILURE',
};

export const registrationRequest = (registrationData) => {
  return {
    type: ActionTypes.REGISTRATION_REQUEST,
    payload: registrationData,
  };
};

export const employeelistingRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_EMPLOYEE_LISTING_REQUEST,
    payload: paginationData,
  };
};

export const employeedetailsRequest = (employee_id) => {
  return {
    type: ActionTypes.GET_EMPLOYEE_DETAILS_REQUEST,
    payload: employee_id,
  };
};

export const updateemployeedetailsRequest = (employeedata) => {
  return {
    type: ActionTypes.UPDATE_EMPLOYEE_DETAILS_REQUEST,
    payload: employeedata,
  };
};

export const updateEmployeeStatusRequest = (employeeid) => {
  return {
    type: ActionTypes.UPDATE_EMPLOYEE_STATUS_REQUEST,
    payload: employeeid,
  };
};