import { ActionTypes } from './socialPlatformActions';

/**
 * @author      Nandani.V.Patel
 * @date        22 Nov 2024
 * @description Social-Platform Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Social-Platform api response  
**/

const defaultState = {
  issociallist: false,
  isupdatestatus: false,
  isadd: false,
  data: undefined,
  adddata: undefined,
};

const socialPlatformReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SOCIAL_PLATFORM_LISTING_REQUEST:
      return {
        ...state,
        issociallist: true,
      };
    case ActionTypes.GET_SOCIAL_PLATFORM_LISTING_SUCCESS:
      return {
        ...state,
        data: action.data,
        issociallist: false,
      };
    case ActionTypes.GET_SOCIAL_PLATFORM_LISTING_FAILURE:
      return {
        ...state,
        issociallist: false,
        error: action.error,
      };
    case ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_REQUEST:
      return {
        ...state,
        isupdatestatus: true,
      };
    case ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_SUCCESS:
      return {
        ...state,
        socialStatus: action.data,
        isupdatestatus: false,
      };
    case ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_FAILURE:
      return {
        ...state,
        isupdatestatus: false,
        error: action.error,
      };
    case ActionTypes.ADD_SOCIAL_PLATFORM_REQUEST:
      return {
        ...state,
        isadd: true,
      };
    case ActionTypes.ADD_SOCIAL_PLATFORM_SUCCESS:
      return {
        ...state,
        adddata: action.data,
        isadd: false,
      };
    case ActionTypes.ADD_SOCIAL_PLATFORM_FAILURE:
      return {
        ...state,
        isadd: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default socialPlatformReducer;