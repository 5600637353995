import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import * as employeeActions from '../employee-register/registerActions';
import { EMPLOYEE_REGISTER } from '../../global/routes';
import AssignTargetModal from '../employee-update-modal';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        20 Nov 2024
 * @description Employee Listing Form.
 * @param
 * @response 
**/

const EmployeeListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employee = useSelector((state) => state?.employeeSignup);
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState('');
  const [employeedata, setEmployeedata] = useState('');
  const [activeStatus, setActiveStatus] = useState({});

  useEffect(() => {
    if (firstInit) {
      dispatch(employeeActions.employeelistingRequest({ page, per_page: perPage }));
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    if (typeof employee?.employeeStatus !== 'undefined' && !employee?.isupdatestatus && employee?.employeeStatus?.status === 'success') {
      // notification(employee?.employeeStatus?.message, 'success');
      dispatch({ type: employeeActions.ActionTypes.UPDATE_EMPLOYEE_STATUS_SUCCESS, employeeStatus: undefined });
      dispatch(employeeActions.employeelistingRequest({ page, per_page: perPage }));
    }
  }, [employee?.employeeStatus, employee?.isupdatestatus]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof employee?.employeelistdata !== 'undefined' && !employee?.isemployeelist) {
      setEmployeedata(employee?.employeelistdata?.data);
    }
  }, [employee?.employeelistdata, employee?.isemployeelist]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  // Close Image modal function
  const handleImageModalClose = () => {
    setIsImageModalOpen(false);
  };

  const handleAssignClick = (data) => {
    setUserInfo(data);
    setIsImageModalOpen(true);
  };

  const handleUpdateStatus = (row) => {
    const currentStatus = activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.user_status;
    const newStatus = !currentStatus;
    setActiveStatus(prevState => ({
      ...prevState,
      [row.id]: newStatus,
    }));
    dispatch(employeeActions.updateEmployeeStatusRequest({ user_id: row.id, status: newStatus }));
  }

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '20px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px',
      },
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: row => row?.user_first_name || "-",
      cell: (row) => `${row?.user_first_name || "-"} ${row?.user_last_name}`,
      sortable: true,
      minWidth: '100px',
      maxWidth: '250px',
    },
    {
      name: 'Type',
      selector: row => row?.user_first_name || "-",
      cell: (row) => row?.employee_tbl_employee_type || "-",
      sortable: true,
      maxWidth: '120px',
    },
    {
      name: 'Email',
      selector: row => row.user_email || '-',
      cell: (row) => row?.user_email || "-",
    },
    {
      name: 'Phone number',
      selector: row => row.user_phone_number || '-',
    },
    {
      name: 'Assign Target',
      selector: row => row.assign_target || 0,
      minWidth: '50px',
      maxWidth: '180px',
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '170px',
      center: 'yes',
      cell: (row) => (
        <>
          <div className="form-check text-primary form-switch me-2">
            <input
              className={` ${styles.iconStyle} ${'form-check-input'}`}
              type="checkbox"
              title='Update Status'
              id={`flexSwitchCheckDefault-${row.id}`}
              checked={activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.user_status} // Use user_status as fallback
              onChange={() => handleUpdateStatus(row, activeStatus[row.id])}
            />
          </div>
          <div
            title='Edit'
            className={`action-btn yellow-action-btn me-2 ${(row.user_status === 0 || row?.assign_target) ? 'disabled' : ''}`}
            onClick={row.user_status !== 0 && (() => handleAssignClick(row.employee_id))}
          >
            <i className="ti ti-edit fs-5" />
          </div>
          {/* <div
            title='View'
            className={`action-btn blue-action-btn`}
          >
            <i className="ti ti-eye fs-5" />
          </div> */}
        </>
      ),
    },
  ];

  return (
    <>
      <div
        className="card-header text-bg-primary mb-3 d-flex justify-content-between align-items-center"
        style={{ paddingTop: '7px', paddingBottom: '7px' }}
      >
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Employee</h3>
        <button
          className="btn btn-outline-light"
          onClick={() => { navigate(EMPLOYEE_REGISTER) }}
        >
          Registration
        </button>
      </div>
      <DataTable
        columns={columns}
        data={employeedata}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={employee?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        progressPending={employee?.isemployeelist}
      />
      {isImageModalOpen && <AssignTargetModal onClose={handleImageModalClose} userInfo={userInfo} />}
    </>
  )
}

export default EmployeeListing;