import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanyOrderActions from '../company/companylistActions';
import DataTable from 'react-data-table-component';
import { COMPANY } from '../../global/routes';
import Breadcrumb from '../breadcrumbs';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        18 Nov 2024
 * @description UI for Company Orders
 * @param
 * @response      
**/

const CompanyOrders = () => {
  const companylist = useSelector((state) => state?.companylist);
  const [companyOrders, setCompanyOrders] = useState([]);
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const dispatch = useDispatch();
  const company_id = location.state?.id ? location.state.id : '';

  const breadcrumbs = [
    { name: 'Company', link: COMPANY },
    { name: 'Campaign Details', link: "" },
  ];

  useEffect(() => {
    if (firstInit) {
      dispatch(CompanyOrderActions.getordersRequest({ company_id: company_id, page, per_page: perPage }));
      setFirstInit(false);
    }
  }, [firstInit, dispatch, company_id]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (companylist?.ordersdata?.status === 'success' && !companylist?.isorders) {
      setCompanyOrders(companylist?.ordersdata?.data);
    }
    dispatch({ type: CompanyOrderActions.ActionTypes.GET_ORDERS_SUCCESS, ordersdata: undefined });
  }, [companylist?.ordersdata, companylist?.isorders]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
      },
    },
    cells: {
      style: {
        padding: "20px 20px",
      }
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px',
      },
    }
  };

  const columns = [
    {
      name: 'Campaign',
      selector: row => row?.id,
    },
    {
      name: 'Purchased Count',
      selector: (row) => row?.purchased_review_count || 0,
      sortable: true
    },
    {
      name: 'Pending Count',
      selector: (row) => row?.pending_reviews_count || 0,
      sortable: true
    },
    {
      name: 'Purchase Date',
      selector: row => new Date(row?.created_at).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      }),
      sortable: true,
    }
  ];

  const getPurchasedSites = (expanded, row) => {
    if (expanded) {
      dispatch(CompanyOrderActions.getSitesByOrder(row.id));
    }
  };

  // expandable row component to list review sites for each order
  const ExpandableRow = ({ data }) => {
    // custom style for review site list
    const siteTableStyles = {
      table: {
        style: {
          padding: '0 30px',
        }
      },
      headRow: {
        style: {
          borderLeft: "solid 1px rgba(0,0,0,.12)",
          borderRight: "solid 1px rgba(0,0,0,.12)",
          fontWeight: "500",
          fontSize: "14px"
        }
      },
      rows: {
        style: {
          borderLeft: "solid 1px rgba(0,0,0,.12)",
          borderRight: "solid 1px rgba(0,0,0,.12)",
          borderBottom: "solid 1px rgba(0,0,0,.12)",
          fontWeight: "400",
          fontSize: "14px",
          paddingTop: "5px",
          paddingBottom: "5px"
        }
      },
    };

    // order based review site listing table columns
    const siteColumns = [
      {
        // name: 'Logo',
        name: 'Review Site',
        selector: (row) => row?.review_site_data?.site_name || "-",
        cell: (row) => (
          <>
            <img
              src={row?.review_site_data?.site_logo || ""}
              alt={row?.review_site_data?.site_name}
              width="50px"
              height="50px"
            />
            <span className='ps-3'>{row?.review_site_data?.site_name || "-"}</span>
          </>
        ),
        sortable: true
      },
      {
        name: 'Purchased Count',
        selector: (row) => row?.review_campaign_tbl_purchased_reviews || 0,
        sortable: true
      },
      {
        name: 'Pending Count',
        selector: (row) => row?.remaining_reviews_count || 0,
        sortable: true
      },
    ];

    return (
      <DataTable
        columns={siteColumns}
        data={companylist.orderedSiteList?.[data?.id] || []}
        customStyles={siteTableStyles}
        progressPending={companylist.orderSitesLoader}
        responsive
      />
    )
  };

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`${'card-header text-bg-primary mb-3 mt-2'} ${styles.headingStyle}`}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Campaign Details</h3>
      </div>
      <DataTable
        columns={columns}
        data={companyOrders}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={companylist?.ordersdata?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        expandableRows
        expandOnRowClicked
        onRowExpandToggled={getPurchasedSites}
        expandableRowsComponent={({ data }) => <ExpandableRow data={data} />}
        progressPending={companylist.isorders}
      />
    </>
  );
}

export default CompanyOrders;
