import { combineReducers } from 'redux'
import signInReducer from '../../component/login/loginReducer';
import logoutReducer from '../../component/logout/logoutReducer';
import userReducer from '../../component/user/userReducer';
import comapnyListingReducer from '../../component/company/companylistReducer';
import teamLeaderListingReducer from '../../component/teamLeader/teamleaderReducer';
import dashboardcountReducer from '../../component/dashboardCount/dashboardcountReducer';
import reviewsiteListingReducer from '../../component/reviewsites/ReviewSitesReducer';
import reviewsiteDetailsReducer from '../../component/reviewsitedetails/reviewsitedetailsReducer';
import planCreationReducer from '../../component/planCreation/planCreationReducer';
import withdrawalReducer from '../../component/withdrawal/withdrawalReducer';
import employeeSignupReducer from '../../component/employee-register/registerReducer';
import assignTargetReducer from '../../component/assign-target/assignTargetReducer';
import socialPlatformReducer from '../../component/social-platform/socialPlatformReducer';

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description rootReducer which combaines all react-application Reducer.
 * @param       
 * @response      
**/

export const rootReducer = combineReducers({
  signin: signInReducer,
  logout: logoutReducer,
  users: userReducer,
  companylist: comapnyListingReducer,
  teamLeader: teamLeaderListingReducer,
  dashboardCounts: dashboardcountReducer,
  reviewsite: reviewsiteListingReducer,
  reviewsitedetails: reviewsiteDetailsReducer,
  plancreation: planCreationReducer,
  withdrawal: withdrawalReducer,
  employeeSignup: employeeSignupReducer,
  assignTarget: assignTargetReducer,
  socialPlatform: socialPlatformReducer,
})