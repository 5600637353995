import { ActionTypes } from './companylistActions';

/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description companylisting Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store companylisting api response  
**/

const defaultState = {
  isCompanyListing: false,
  iswebsite: false,
  isorders: false,
  ordersdata: undefined,
};

const comapnyListingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.COMPANY_LISTING_REQUEST:
      return {
        ...state,
        isCompanyListing: true,
      };
    case ActionTypes.COMPANY_LISTING_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCompanyListing: false,
      };
    case ActionTypes.COMPANY_LISTING_FAILURE:
      return {
        ...state,
        isCompanyListing: false,
        error: action.error,
      };
    case ActionTypes.GET_WEBSITE_REQUEST:
      return {
        ...state,
        iswebsite: true,
      };
    case ActionTypes.GET_WEBSITE_SUCCESS:
      return {
        ...state,
        website: action.data,
        iswebsite: false,
      };
    case ActionTypes.GET_WEBSITE_FAILURE:
      return {
        ...state,
        iswebsite: false,
        error: action.error,
      };
    case ActionTypes.COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        isCompanyDetails: true,
      };
    case ActionTypes.COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCompanyDetails: false,
      };
    case ActionTypes.COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        isCompanyDetails: false,
        error: action.error,
      };
    case ActionTypes.GET_ORDERS_REQUEST:
      return {
        ...state,
        isorders: true,
      };
    case ActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        ordersdata: action.data,
        isorders: false,
      };
    case ActionTypes.GET_ORDERS_FAILURE:
      return {
        ...state,
        isorders: false,
        error: action.error,
      };
    case ActionTypes.GET_SITES_BY_ORDER_REQUEST:
      return {
        ...state,
        orderSitesLoader: true
      };
    case ActionTypes.GET_SITES_BY_ORDER_SUCCESS:
      return {
        ...state,
        orderedSiteList: {
          ...state.orderedSiteList,
          [action.payload?.order_id]: action.payload?.review_campaigns || []
        },
        orderSitesLoader: false
      };
    case ActionTypes.GET_SITES_BY_ORDER_FAILURE:
      return {
        ...state,
        orderSitesLoader: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default comapnyListingReducer;