import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import * as EmployeeActions from '../employee-register/registerActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';

/**
 * @author      Nandani.V.Patel
 * @date        21 th Nov 2024
 * @description UI Implementation of Assign-target update Modal
 * @param
 * @response 
**/

const AssignTargetModal = ({ onClose, userInfo }) => {
  const { handleSubmit, register, resetField, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const employee = useSelector((state) => state?.employeeSignup);

  useEffect(() => {
    if (employee?.employeeupdatedata !== undefined && employee?.employeeupdatedata?.status === 'success' && !employee.isupdate) {
      notification(employee?.employeeupdatedata?.message, 'success');
      dispatch({ type: EmployeeActions.ActionTypes.UPDATE_EMPLOYEE_DETAILS_SUCCESS, employeeupdatedata: undefined });
      resetField('target');
    }
    if (employee?.employeeupdatedata === undefined && employee?.error !== undefined) {
      notification(employee?.error?.message, 'error');
      dispatch({ type: EmployeeActions.ActionTypes.UPDATE_EMPLOYEE_DETAILS_FAILURE, error: undefined });
    }
  }, [employee?.employeeupdatedata, employee?.isupdate, employee?.error]);

  const onSubmit = (data) => {
    const payload = {
      employee_id: userInfo,
      target: parseInt(data.target),
    };
    dispatch(EmployeeActions.updateemployeedetailsRequest(payload));
    onClose();
  }

  return (
    <>
      <div
        className="modal fade show d-block"
        id="assignTarget"
        tabIndex={-1}
        aria-labelledby="assignTargetLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="assignTargetLabel">
                Assign Target
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <div className="col-md-12">
                  <div className="form-floating">
                    <input
                      type="number"
                      {...register('target', {
                        required: 'Target is required.',
                        pattern: {
                          value: /^[1-9][0-9]*$/, // Allow 0 and positive integers
                          message: 'Target must be 1 or a positive integer.',
                        },
                      })}
                      id='assign_target'
                      className="form-control mb-0 text-black fs-4 "
                      placeholder='Target'
                    />
                    <label htmlFor='assign_target' className="col-md-2 col-form-label fs-4">
                      Targets
                    </label>
                  </div>
                  {errors?.target && <span style={{ fontSize: '14px', color: 'red' }}>{errors.target.message}</span>}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit(onSubmit)}
              >
                {"Assign"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
      <NotificationContainer />
    </>
  )
}
export default AssignTargetModal;