import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DASHBOARD } from '../../global/routes';
import { useForm } from "react-hook-form";
import { rememberMeKey, passKey, emailKey, encryptStorage, isAuth } from '../../utils/auth';
import { NotificationContainer } from '../notificationContainer';
import { notification } from '../notificationContainer/notification';
import * as LoginActions from './loginActions';
import * as LogoutActions from '../logout/logoutAction';
import styles from './styles.module.scss';
import { validationRegex } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        20th March 2024
 * @description User Login Form and handling login-api call.
 * @param
 * @response 
**/

const Login = (props) => {
  const { register, handleSubmit, setValue, formState: { errors }, } = useForm();
  const { signin, actions } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setchecked] = useState(false);
  const [isredirect, setIsRedirect] = useState(false);
  const userlogout = useSelector((state) => state?.logout);

  const auth = isAuth();
  useEffect(() => {
    if (auth) {
      navigate(DASHBOARD);
    }
  }, [auth, navigate]);

  const handleChange = () => {
    setchecked(!checked);
  };

  useEffect(() => {
    if (userlogout?.data !== undefined && userlogout?.data !== null && userlogout?.data !== '') {
      if (userlogout?.data?.status === 'success') {
        notification(userlogout?.data?.message, 'success');
      }
      dispatch({ type: LogoutActions.ActionTypes.LOGOUT_SUCCESS, data: undefined });
    }
  }, [userlogout?.data, userlogout?.status]);

  const remkey = encryptStorage.getItem(rememberMeKey) === true;
  useEffect(() => {
    if (remkey) {
      setValue('email', encryptStorage.getItem(emailKey) || '');
      setValue('password', encryptStorage.getItem(passKey) || '');
      setValue('checked', true);
      setchecked(true);
    }
  }, [remkey]);

  useEffect(() => {
    if (signin?.data !== undefined && signin?.data?.status === 'success' && !signin.isLogin) {
      notification(signin?.data?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: LoginActions.ActionTypes.LOGIN_SUCCESS, data: undefined });
    }
    if (signin?.data === undefined && signin.error !== undefined) {
      setIsRedirect(false);
      notification(signin?.error?.message, 'error');
    }
    dispatch({ type: LoginActions.ActionTypes.LOGIN_FAILURE, error: undefined });
  }, [signin?.data, signin.isLogin, signin.error]);


  useEffect(() => {
    if (isredirect) {
      navigate(DASHBOARD);
    }
  }, [isredirect])

  const onSubmit = (data) => {
    if (checked) {
      encryptStorage.setItem(rememberMeKey, checked);
      encryptStorage.setItem(emailKey, data.email);
      encryptStorage.setItem(passKey, data.password);
    } else {
      encryptStorage.removeItem(rememberMeKey);
      encryptStorage.removeItem(emailKey);
      encryptStorage.removeItem(passKey);
    }
    actions.loginRequest(data);
    dispatch({ type: LoginActions.ActionTypes.LOGIN_FAILURE, error: undefined });
    dispatch({ type: LoginActions.ActionTypes.LOGIN_SUCCESS, data: undefined });
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);
    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [])

  return (
    <div className="app-wrapper">
      <div
        className="position-relative overflow-hidden min-vh-100 d-flex align-items-center justify-content-center"
        style={{ backgroundColor: 'var(--theme-blue-color)' }}
      >
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <div className="card mb-0">
                <div className="card-body">
                  <span

                    className="text-nowrap logo-img text-center d-block py-3 w-100"
                  >
                    <img
                      src="../assets/images/logos/logo.png"
                      width={180}
                      alt="Not Found"
                    />
                  </span>
                  <form>
                    <div className="mb-3">
                      <label className="form-label">
                        Email
                      </label>
                      <span className={styles.errorstar}>*</span>
                      <input
                        type="email"
                        {...register("email", {
                          required: 'Email is required.',
                          pattern: {
                            value: validationRegex.email,
                            message: 'Please Enter a valid Email.',
                          },
                        })}
                        className="form-control"
                      />
                      {errors?.email?.type === 'required' && <span className={styles.errormsg}>{errors.email.message}</span>}
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Password
                      </label>
                      <span className={styles.errorstar}>*</span>
                      <input
                        type="password"
                        {...register("password", {
                          required: 'Password is required.'
                        })}
                        className="form-control"
                      />
                      {errors?.password?.type === 'required' && <span className={styles.errormsg}>{errors.password.message}</span>}
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="form-check">
                        <input
                          className="form-check-input primary"
                          id='rememberMeCheck'
                          type="checkbox"
                          onChange={handleChange}
                          checked={checked}
                        />
                        <label className="form-check-label text-dark" htmlFor='rememberMeCheck' >
                          Remeber this device
                        </label>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Sign In
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      <NotificationContainer />
    </div>
  )
}
const mapStateToProps = (state) => ({
  signin: state.signin,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(LoginActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);