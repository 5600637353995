import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './socialPlatformActions';
import { postFileRequestAPI, postRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        22 Nov 2024
 * @description Social Platform Saga and handling API response.
 * @param       Social Platform Request paylod
 * @response    Social Platform is success or fail.  
**/

function* socialPlatformlistingSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/social-platform', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_SOCIAL_PLATFORM_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_SOCIAL_PLATFORM_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_SOCIAL_PLATFORM_LISTING_FAILURE, error: err?.response?.data });
  }
}

function* socialPlatformStatusUpdateSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/update-social-platform-status', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_FAILURE, error: err?.response?.data });
  }
}

function* addSocialPlatformSaga(action) {
  try {
    const response = yield call(postFileRequestAPI, { url: 'api/admin/store-social-platform', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.ADD_SOCIAL_PLATFORM_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.ADD_SOCIAL_PLATFORM_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.ADD_SOCIAL_PLATFORM_FAILURE, error: err?.response?.data });
  }
}

function* watchSocialPlatformRequest() {
  yield takeLatest(ActionTypes.GET_SOCIAL_PLATFORM_LISTING_REQUEST, socialPlatformlistingSaga);
  yield takeLatest(ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_REQUEST, socialPlatformStatusUpdateSaga);
  yield takeLatest(ActionTypes.ADD_SOCIAL_PLATFORM_REQUEST, addSocialPlatformSaga);
}

function* socialPlatformSaga() {
  yield fork(watchSocialPlatformRequest);
}

export default socialPlatformSaga;