import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './companylistActions';
import { postRequestAPI, getRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description Registration  Saga and handling API response.
 * @param       company-listing Request paylod
 * @response    company listing  is success or fail.  
**/

function* companylistSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/get-companies', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.COMPANY_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.COMPANY_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.COMPANY_LISTING_FAILURE, error: err?.response?.data });
  }
}

function* websiteSaga() {
  try {
    const response = yield call(postRequestAPI, { url: 'api/get-websites' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_WEBSITE_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_WEBSITE_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_WEBSITE_FAILURE, error: err?.response?.data });
  }
}

function* CompanydetailsSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/company-details', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.COMPANY_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.COMPANY_DETAILS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.COMPANY_DETAILS_FAILURE, error: err?.response?.data });
  }
}

function* ordersSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/company/get-order-history', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_ORDERS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_ORDERS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_ORDERS_FAILURE, error: err?.response?.data });
  }
}

function* getSitesByOrderReq(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, {
      url: `api/company/get-order-details/${action.payload}`,
      data: action.payload
    });
    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_SITES_BY_ORDER_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_SITES_BY_ORDER_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_SITES_BY_ORDER_FAILURE, error: err?.response?.data });
  }
}

function* watchCompanyListingRequest() {
  yield takeLatest(ActionTypes.COMPANY_LISTING_REQUEST, companylistSaga);
  yield takeLatest(ActionTypes.GET_WEBSITE_REQUEST, websiteSaga);
  yield takeLatest(ActionTypes.COMPANY_DETAILS_REQUEST, CompanydetailsSaga);
  yield takeLatest(ActionTypes.GET_ORDERS_REQUEST, ordersSaga);
  yield takeLatest(ActionTypes.GET_SITES_BY_ORDER_REQUEST, getSitesByOrderReq);
}

function* companyListingSaga() {
  yield fork(watchCompanyListingRequest);
}

export default companyListingSaga;