import { ActionTypes } from './planCreationActions';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description plancreation Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Plancreation api response  
**/

const defaultState = {
  isPlancreation: false,
  iswebsite: false,
  isupdatereviewsite: false,
  isreviewsitediscount: false,
  isdeletediscount: false,
  iscurrencies: false,
  siteCurrencyAmount: [],
  withdrawalPercent: "",
  percentLoad: false,
  setPercentLoader: false,
};

const planCreationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.PLAN_CREATION_REQUEST:
      return {
        ...state,
        isPlancreation: true,
      };
    case ActionTypes.PLAN_CREATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isPlancreation: false,
      };
    case ActionTypes.PLAN_CREATION_FAILURE:
      return {
        ...state,
        isPlancreation: false,
        error: action.error,
      };
    case ActionTypes.GET_WEBSITE_REQUEST:
      return {
        ...state,
        iswebsite: true,
      };
    case ActionTypes.GET_WEBSITE_SUCCESS:
      return {
        ...state,
        website: action.data,
        iswebsite: false,
      };
    case ActionTypes.GET_WEBSITE_FAILURE:
      return {
        ...state,
        iswebsite: false,
        error: action.error,
      };
    case ActionTypes.UPDATE_REVIEW_SITE_PRICE_REQUEST:
      return {
        ...state,
        isupdatereviewsite: true,
      };
    case ActionTypes.UPDATE_REVIEW_SITE_PRICE_SUCCESS:
      return {
        ...state,
        reviewSitePriceData: action.data,
        isupdatereviewsite: false,
      };
    case ActionTypes.UPDATE_REVIEW_SITE_PRICE_FAILURE:
      return {
        ...state,
        isupdatereviewsite: false,
        error: action.error,
      };
    case ActionTypes.GET_REVIEW_SITE_DISCOUNT_REQUEST:
      return {
        ...state,
        isreviewsitediscount: true,
      };
    case ActionTypes.GET_REVIEW_SITE_DISCOUNT_SUCCESS:
      return {
        ...state,
        reviewsitediscount: action.data,
        isreviewsitediscount: false,
      };
    case ActionTypes.GET_REVIEW_SITE_DISCOUNT_FAILURE:
      return {
        ...state,
        isreviewsitediscount: false,
        error: action.error,
      };
    case ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_REQUEST:
      return {
        ...state,
        isdeletediscount: true,
      };
    case ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_SUCCESS:
      return {
        ...state,
        reviewSiteDiscount: action.data,
        isdeletediscount: false,
      };
    case ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_FAILURE:
      return {
        ...state,
        isdeletediscount: false,
        error: action.error,
      };
    case ActionTypes.GET_CURRENCIES_REQUEST:
      return {
        ...state,
        iscurrencies: true,
      };
    case ActionTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.data,
        iscurrencies: false,
      };
    case ActionTypes.GET_CURRENCIES_FAILURE:
      return {
        ...state,
        iscurrencies: false,
        error: action.error,
      };
    case ActionTypes.GET_SITE_CURRENCY_AMOUNT:
      return {
        ...state,
        isAmount: true,
      };
    case ActionTypes.GET_SITE_CURRENCY_AMOUNT_SUCCESS:
      return {
        ...state,
        siteCurrencyAmount: action.data,
        isAmount: false,
      };
    case ActionTypes.GET_SITE_CURRENCY_AMOUNT_FAILURE:
      return {
        ...state,
        isAmount: false,
        error: action.error,
      };
    case ActionTypes.SET_WITHDRAWAL_PERCENT:
      return {
        ...state,
        setPercentLoader: true,
      }
    case ActionTypes.SET_WITHDRAWAL_PERCENT_SUCCESS:
      return {
        ...state,
        setPercentLoader: false,
        withdrawalPercent: action.data.data?.min_target_complete_percent,
        successMsg: action.data.message
      }
    case ActionTypes.SET_WITHDRAWAL_PERCENT_FAILURE:
      return {
        ...state,
        setPercentLoader: false,
        error: action.error,
      }
    case ActionTypes.GET_WITHDRAWAL_PERCENT:
      return {
        ...state,
        percentLoad: true,
      }
    case ActionTypes.GET_WITHDRAWAL_PERCENT_SUCCESS:
      return {
        ...state,
        withdrawalPercent: action.data,
        percentLoad: false,
      }
    case ActionTypes.GET_WITHDRAWAL_PERCENT_FAILURE:
      return {
        ...state,
        percentLoad: false,
        error: action.error,
      }
    default:
      return state;
  }
}

export default planCreationReducer;