import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import LazyLoad from 'react-lazy-load';
import { ASSIGNTARGET } from '../../../global/routes';
import Breadcrumb from '../../breadcrumbs';

import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        19 Nov 2024
 * @description Assign-Target Purchased-Site Team-leader listing
 * @param
 * @response 
**/

const TeamleadersList = () => {
  const { register, handleSubmit, resetField, formState: { errors } } = useForm();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company_name = queryParams.get('name');
  const company_id = queryParams.get('id');
  const site_id = queryParams.get('siteid');

  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState([]);
  const listRef = useRef(null);

  const breadcrumbs = [
    { name: 'Assign Target', link: ASSIGNTARGET },
    { name: 'Purchase-Site', link: `${ASSIGNTARGET}?name=${company_name}&id=${company_id}` },
    { name: 'Team Leaders', link: "" },
  ];

  // Static team leader data
  const staticTeamleaderData = [
    { id: '1', name: 'Nandani Patel' },
    { id: '2', name: 'Shruti Bhutani' },
    { id: '3', name: 'Deep Javiya' },
    { id: '4', name: 'Shreya Gandhi' },
    { id: '5', name: 'Roshan Desai' },
    { id: '6', name: 'Ankita Mehta' },
    { id: '7', name: 'Neha Sharma' },
    { id: '8', name: 'Pooja Gupta' },
    { id: '9', name: 'Ravi Verma' },
    { id: '10', name: 'Rohan Desai' },
    { id: '11', name: 'Arjun Singh' },
    { id: '12', name: 'Aditi Rao' },
    { id: '13', name: 'Priya Kapoor' },
    { id: '14', name: 'Karan Patel' },
    { id: '15', name: 'Maya Iyer' },
    { id: '16', name: 'Nandani Patel' },
    { id: '17', name: 'Shruti Bhutani' },
    { id: '18', name: 'Deep Javiya' },
    { id: '19', name: 'Shreya Gandhi' },
    { id: '20', name: 'Roshan Desai' },
    { id: '21', name: 'Ankita Mehta' },
    { id: '22', name: 'Neha Sharma' },
    { id: '23', name: 'Ankita Mehta' },
    { id: '24', name: 'Neha Sharma' },
    { id: '25', name: 'Pooja Gupta' },
    { id: '26', name: 'Ravi Verma' },
    { id: '27', name: 'Rohan Desai' },
    { id: '28', name: 'Arjun Singh' },
    { id: '29', name: 'Aditi Rao' },
    { id: '30', name: 'Priya Kapoor' },
    { id: '31', name: 'Karan Patel' },
    { id: '32', name: 'Maya Iyer' },
    { id: '33', name: 'Ravi Gupta' },
    { id: '34', name: 'Neelam Patel' },
    { id: '35', name: 'Ramesh Verma' },
    { id: '36', name: 'Simran Sharma' },
    { id: '37', name: 'Sonal Bhutani' },
    { id: '38', name: 'Rohit Desai' },
    { id: '39', name: 'Surbhi Kapoor' },
    { id: '40', name: 'Kavita Mehta' },
    { id: '41', name: 'Niharika Soni' },
    { id: '42', name: 'Sumit Verma' },
    { id: '43', name: 'Tanu Garg' },
    { id: '44', name: 'Parveen Rani' },
    { id: '45', name: 'Rohit Patel' },
    { id: '46', name: 'Kiran Mehta' },
    { id: '47', name: 'Anju Rani' },
    { id: '48', name: 'Ravi Sharma' },
    { id: '49', name: 'Maya Gupta' },
    { id: '50', name: 'Arjun Mehta' },
    { id: '51', name: 'Amit Patel' },
    { id: '52', name: 'Nitin Kapoor' },
    { id: '53', name: 'Vinod Javiya' },
    { id: '54', name: 'Swati Verma' },
    { id: '55', name: 'Pooja Yadav' },
    { id: '56', name: 'Ravi Yadav' },
    { id: '57', name: 'Priya Singh' },
    { id: '58', name: 'Rohit Mehta' },
    { id: '59', name: 'Vishal Kapoor' },
    { id: '60', name: 'Manish Sharma' },
    { id: '61', name: 'Ankush Desai' },
    { id: '62', name: 'Vishal Verma' },
    { id: '63', name: 'Aarti Gupta' },
    { id: '64', name: 'Sushma Patel' },
    { id: '65', name: 'Ravi Singh' },
    { id: '66', name: 'Saurabh Verma' },
    { id: '67', name: 'Shubham Desai' },
    { id: '68', name: 'Jaya Sharma' },
    { id: '69', name: 'Ashish Patel' },
    { id: '70', name: 'Amit Yadav' },
    { id: '71', name: 'Sonali Verma' },
    { id: '72', name: 'Ruchi Gupta' },
    { id: '73', name: 'Vikram Rani' },
    { id: '74', name: 'Vikash Yadav' },
    { id: '75', name: 'Shalini Verma' },
    { id: '76', name: 'Amit Sharma' },
    { id: '77', name: 'Gaurav Desai' },
    { id: '78', name: 'Priya Rani' },
    { id: '79', name: 'Vikas Gupta' },
    { id: '80', name: 'Aarti Kapoor' },
    { id: '81', name: 'Harish Sharma' },
    { id: '82', name: 'Kunal Desai' },
    { id: '83', name: 'Kiran Gupta' },
    { id: '84', name: 'Rishabh Verma' },
    { id: '85', name: 'Amit Mehta' },
    { id: '86', name: 'Suman Verma' },
    { id: '87', name: 'Aman Singh' },
    { id: '88', name: 'Kanchan Patel' },
    { id: '89', name: 'Shreya Kapoor' },
    { id: '90', name: 'Madhavi Gupta' },
    { id: '91', name: 'Priyanka Sharma' },
    { id: '92', name: 'Ravi Patel' },
    { id: '93', name: 'Ananya Yadav' },
    { id: '94', name: 'Gita Desai' },
    { id: '95', name: 'Shyam Verma' },
    { id: '96', name: 'Gauri Mehta' },
    { id: '97', name: 'Tanya Kapoor' },
    { id: '98', name: 'Jitendra Verma' },
    { id: '99', name: 'Hina Mehta' },
    { id: '100', name: 'Rajesh Gupta' },
    { id: '101', name: 'Sunil Yadav' },
    { id: '102', name: 'Suman Yadav' },
    { id: '103', name: 'Geeta Kapoor' },
    { id: '104', name: 'Pradeep Gupta' },
    { id: '105', name: 'Vishal Yadav' },
    { id: '106', name: 'Krishna Rani' },
    { id: '107', name: 'Abhishek Mehta' },
    { id: '108', name: 'Kavita Sharma' },
    { id: '109', name: 'Vijay Desai' },
    { id: '110', name: 'Shalini Gupta' },
    { id: '111', name: 'Kiran Yadav' },
    { id: '112', name: 'Usha Kapoor' },
    { id: '113', name: 'Tanu Rani' },
    { id: '114', name: 'Vikram Patel' },
    { id: '115', name: 'Nisha Verma' },
    { id: '116', name: 'Anil Gupta' },
    { id: '117', name: 'Kajal Patel' },
    { id: '118', name: 'Madhuri Sharma' },
    { id: '119', name: 'Uday Mehta' },
    { id: '120', name: 'Geeta Singh' },
    { id: '121', name: 'Shubham Kapoor' },
    { id: '122', name: 'Tanu Mehta' },
    { id: '123', name: 'Harsh Yadav' },
    { id: '124', name: 'Laxmi Sharma' },
    { id: '125', name: 'Nitin Gupta' },
    { id: '126', name: 'Suraj Singh' },
    { id: '127', name: 'Mina Yadav' },
    { id: '128', name: 'Rohit Kapoor' },
    { id: '129', name: 'Harsha Patel' },
    { id: '130', name: 'Tina Sharma' },
    { id: '131', name: 'Rohini Mehta' },
    { id: '132', name: 'Krishna Kapoor' },
    { id: '133', name: 'Sunita Gupta' },
    { id: '134', name: 'Kajal Yadav' },
    { id: '135', name: 'Rakesh Singh' },
    { id: '136', name: 'Anjali Gupta' },
    { id: '137', name: 'Rohit Mehta' },
    { id: '138', name: 'Mitali Sharma' },
    { id: '139', name: 'Ashok Kapoor' },
    { id: '140', name: 'Rekha Patel' },
    { id: '141', name: 'Ashwini Yadav' },
    { id: '142', name: 'Puneet Desai' },
    { id: '143', name: 'Shalini Patel' },
    { id: '144', name: 'Geetanjali Kapoor' },
    { id: '145', name: 'Ananya Kapoor' },
    { id: '146', name: 'Madhuri Mehta' },
    { id: '147', name: 'Neelam Yadav' },
    { id: '148', name: 'Rajeev Mehta' },
    { id: '149', name: 'Preeti Gupta' },
    { id: '150', name: 'Ravi Mehta' },
  ];

  // Determine the current data slice to display
  const getDataToDisplay = (page) => {
    const startIndex = (page - 1) * perPage;
    const endIndex = page * perPage;
    return staticTeamleaderData.slice(startIndex, endIndex);
  };

  const handleScroll = () => {
    const container = listRef.current;
    if (
      container.scrollTop + container.clientHeight >= container.scrollHeight - 50 &&
      displayedData.length < staticTeamleaderData.length &&
      !loading
    ) {
      setLoading(true);
      setTimeout(() => {
        setPage((prev) => prev + 1);
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    setDisplayedData(getDataToDisplay(1));
  }, []);

  useEffect(() => {
    // Append new data to the displayed list when the page changes
    if (page > 1) {
      setDisplayedData((prevData) => [
        ...prevData,
        ...getDataToDisplay(page),
      ]);
    }
  }, [page]);

  useEffect(() => {
    const container = listRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [displayedData]);

  const onSubmit = (data) => {
    console.log('Submitted data:', data);
  };

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`${'card-header text-bg-primary mb-3 mt-2'} ${styles.headingStyle}`}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Team Leaders</h3>
      </div>
      <div
        ref={listRef}
        className="list-group mb-3"
        style={{
          maxHeight: 'calc(100vh - 290px)',
          overflowY: 'auto',
          height: '100%'
        }}
      >
        {displayedData.map((leader) => {
          const fieldName = `count_${leader.id}`;
          return (
            <LazyLoad key={leader.id} height={100} offset={100}>
              <li
                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '1rem' }}
                className="list-group-item d-flex justify-content-between align-items-center mb-0"
              >
                <div style={{ flex: 1 }}>
                  <span>{leader.name}</span>
                </div>
                <div style={{ flex: 1 }}>
                  <input
                    type="text"
                    {...register(fieldName, {
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/, // Allow 0 and positive integers
                        message: 'Amount must be 0 or a positive integer.',
                      },
                    })}
                    className="form-control mb-0 text-black fs-4 "
                    placeholder="Assign Count"
                    style={{ color: 'black', borderColor: '#aec3ff' }}
                  />
                  {errors[fieldName] && (
                    <span className={styles.errormsg}>
                      {errors[fieldName]?.message}
                    </span>
                  )}
                </div>
              </li>
            </LazyLoad>
          );
        })}
        {loading && (
          <div className="text-center my-2">
            <div className="spinner-border text-primary " style={{ width: '3rem', height: '3rem' }} role="status" />
          </div>
        )}
      </div>

      <div className="text-end">
        <button
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default TeamleadersList;