import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ASSIGNTARGET } from '../../global/routes';
import * as AssignTargrtActions from './assignTargetActions';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        19 Nov 2024
 * @description UI Implementation of Company Assign-Target.
 * @param
 * @response 
**/

const AssignTarget = (props) => {
  const { assignTarget, actions } = props;
  const navigate = useNavigate();
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const staticCompanyListData = [
    {
      id: '1',
      company_name: 'Air India',
      no_of_order_site: '5',
      purchase_count: '1',
      assign_count: '5',
    },
    {
      id: '2',
      company_name: 'Bajaj',
      no_of_order_site: '2',
      purchase_count: '3',
      assign_count: '7',
    },
    {
      id: '3',
      company_name: 'Mahindra',
      no_of_order_site: '1',
      purchase_count: '5',
      assign_count: '9',
    },
    {
      id: '4',
      company_name: 'Hero',
      no_of_order_site: '3',
      purchase_count: '2',
      assign_count: '3',
    },
    {
      id: '5',
      company_name: "Domino's",
      no_of_order_site: '7',
      purchase_count: '6',
      assign_count: '10',
    },
  ];

  useEffect(() => {
    if (firstInit) {
      // actions.assigntargetcompanyRequest({ page, per_page: perPage })
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '20px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px'
      },
    }
  };

  const columns = [
    {
      name: 'Company Name',
      selector: row => row?.company_name || '-',
      sortable: true,
      minWidth: '160px',
    },
    {
      name: 'No of Site',
      selector: row => row?.no_of_order_site || '-',
      sortable: true,
      minWidth: '170px',
    },
    {
      name: 'Purchased Count',
      selector: row => row?.purchase_count || '-',
      minWidth: '170px',
    },
    {
      name: 'Assigned Count',
      selector: row => row?.assign_count || '-',
      minWidth: '130px',
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <div>
          <div
            title='View'
            className={`action-btn blue-action-btn`}
            onClick={() => {
              const encodedName = encodeURIComponent(row?.company_name); // Encode the name for URL
              navigate(`${ASSIGNTARGET}?name=${encodedName}&id=${row?.id}`); // Construct the URL
            }}
          >
            <i className="ti ti-eye fs-5" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Assign Target to TL</h3>
      </div>
      <DataTable
        columns={columns}
        data={staticCompanyListData}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        // paginationTotalRows={withdrawal?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        progressPending={assignTarget?.isCompanylist}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  assignTarget: state.assignTarget,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AssignTargrtActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignTarget);