import { ActionTypes } from './assignTargetActions';

/**
 * @author      Nandani.V.Patel
 * @date        21 Nov 2024
 * @description assign-taregt Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store assign-taregt api response  
**/

const defaultState = {
  isCompanylist: false,
  isCompanySite: false,
  isTeamleader: false,
  companySites: undefined,
  companyTeamleader: undefined
};

const assignTargetReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_REQUEST:
      return {
        ...state,
        isCompanylist: true,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCompanylist: false,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_FAILURE:
      return {
        ...state,
        isCompanylist: false,
        error: action.error,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_REQUEST:
      return {
        ...state,
        isCompanySite: true,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_SUCCESS:
      return {
        ...state,
        companySites: action.data,
        isCompanySite: false,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_FAILURE:
      return {
        ...state,
        isCompanySite: false,
        error: action.error,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_REQUEST:
      return {
        ...state,
        isTeamleader: true,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_SUCCESS:
      return {
        ...state,
        companyTeamleader: action.data,
        isTeamleader: false,
      };
    case ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_FAILURE:
      return {
        ...state,
        isTeamleader: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default assignTargetReducer;