import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import * as CompanylistingActions from './companylistActions';
import { useNavigate } from 'react-router-dom';
import { COMPANY } from '../../global/routes';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description UI for Company
 * @param
 * @response      
**/

const Company = (props) => {
  const { companylist, actions } = props;
  const navigate = useNavigate();
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const [companies, setcompanies] = useState('');

  useEffect(() => {
    if (firstInit) {
      actions.companylistingRequest({ page, perPage });
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof companylist?.data !== 'undefined' && !companylist?.isCompanyListing) {
      setcompanies(companylist?.data?.data);
    }
  }, [companylist?.data, companylist?.isCompanyListing]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: '600'
      },
    },
    rows: {
      style: {
        fontSize: '1rem',
        fontWeight: '300',
        padding: '20px 0px'
      },
    },
    pagination: {
      style: {
        fontSize: '1rem',
        fontWeight: '600',
        padding: '10px',
      },
    }
  };

  const columns = [
    {
      name: 'Company Name',
      selector: row => row?.company_tbl_name,
      sortable: true,
    },
    // {
    //   name: 'Company Country',
    //   selector: row => row?.country_name,
    //   sortable: true,
    // },
    // {
    //   name: 'Company No',
    //   selector: row => row?.company_tbl_mob_no,
    //   sortable: true,
    // },
    {
      name: 'Category Name',
      selector: row => row.category?.category_name || '-',
      sortable: true,
    },
    {
      name: 'Total Campaigns',
      selector: row => row?.total_campaigns,
      sortable: true,
    },
    {
      name: 'Pending Campaigns',
      selector: row => row?.pending_campaigns,
      sortable: true,
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <>
          <div
            title='View'
            className={`action-btn blue-action-btn`}
            style={{ marginRight: '10px' }}
            onClick={() => navigate(`${COMPANY}/${row?.id}`)}
          >
            <i className="ti ti-eye fs-5" />
          </div>
          <div
            title='View Campaigns'
            className={`action-btn yellow-action-btn`}
            onClick={() => {
              const encodedName = encodeURIComponent(row?.company_tbl_name || "company");
              navigate(`/${encodedName}/campaigns`, { state: { id: row?.id } });
            }}
          >
            <i className={`${'ti ti-list'} ${styles.listicon}`}></i>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Companies</h3>
      </div>
      <DataTable
        columns={columns}
        data={companies}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={companylist?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        customStyles={customStyles}
        progressPending={companylist?.isCompanyListing}
      />
    </>
  )
}
const mapStateToProps = (state) => ({
  companylist: state.companylist,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CompanylistingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);