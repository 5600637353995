import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './assignTargetActions';
import { postRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        21 Nov 2024
 * @description assign taregt Saga and handling API response.
 * @param       assign taregt Request paylod
 * @response    assign taregt is success or fail.  
**/

function* assigntargetcompanylisitngSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: '', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_FAILURE, error: err?.response?.data });
  }
}

function* assigntargetcompanysiteSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: '', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_FAILURE, error: err?.response?.data });
  }
}

function* watchAssignTargetRequest() {
  yield takeLatest(ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_REQUEST, assigntargetcompanylisitngSaga);
  yield takeLatest(ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_REQUEST, assigntargetcompanysiteSaga);
}

function* assignTargetSaga() {
  yield fork(watchAssignTargetRequest);
}

export default assignTargetSaga;