import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import userimg from '../../assest/user.jpg'

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description Dashboard-Header design and Logout api call.
 * @param
 * @response 
**/

const Header = ({ sidemenu, handlesidebarload }) => {
  const [showmenu, setShowmenu] = useState(false);

  useEffect(() => {
    setShowmenu(sidemenu);
  }, [sidemenu]);

  const handlesideMenu = () => {
    handlesidebarload(!showmenu);
  }

  return (
    <>
      <header
        className={`${'app-header'} ${styles.headerstyle}`}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <ul className="navbar-nav">
            <li className={` ${styles.sidemenuicon} nav-item d-block d-xl-none`} onClick={handlesideMenu}>
              <p
                className="nav-link sidebartoggler"
                id="headerCollapse"
              >
                <i className="ti ti-menu-2" style={{ color: '#fff' }} />
              </p>
            </li>
          </ul>
          <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li className="nav-item dropdown">
                <p
                  className="nav-link nav-icon-hover"
                  id="drop2"
                >
                  <img src={userimg} alt="profile" width="45" height="45" className="rounded-circle" />
                </p>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}
export default Header;