import React from 'react';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description Dashboard-Body design.
 * @param
 * @response 
**/

const DashboardBody = (props) => {
  return (
    <>
      <div className="container-fluid" style={{ maxWidth: '100%' }}>
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="col-lg-12 d-flex align-items-strech">
            <div className="card w-100">
              <div className="card-body">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardBody;