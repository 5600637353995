import { ActionTypes } from './registerActions';

/**
 * @author      Nandani.V.Patel
 * @date        20 Nov 2024
 * @description Registration Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Registartion api response  
**/

const defaultState = {
  isRegistration: false,
  isemployeelist: false,
  isemployeedetail: false,
  isupdate: false,
  isupdatestatus: false,
  employeelistdata: undefined,
  employeedetailsdata: undefined,
  employeeupdatedata: undefined,
  employeeStatus: undefined
};

const employeeSignupReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTRATION_REQUEST:
      return {
        ...state,
        isRegistration: true,
      };
    case ActionTypes.REGISTRATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isRegistration: false,
      };
    case ActionTypes.REGISTRATION_FAILURE:
      return {
        ...state,
        isRegistration: false,
        error: action.error,
      };
    case ActionTypes.GET_EMPLOYEE_LISTING_REQUEST:
      return {
        ...state,
        isemployeelist: true,
      };
    case ActionTypes.GET_EMPLOYEE_LISTING_SUCCESS:
      return {
        ...state,
        employeelistdata: action.data,
        isemployeelist: false,
      };
    case ActionTypes.GET_EMPLOYEE_LISTING_FAILURE:
      return {
        ...state,
        isemployeelist: false,
        error: action.error,
      };
    case ActionTypes.GET_EMPLOYEE_DETAILS_REQUEST:
      return {
        ...state,
        isemployeedetail: true,
      };
    case ActionTypes.GET_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        employeedetailsdata: action.data,
        isemployeedetail: false,
      };
    case ActionTypes.GET_EMPLOYEE_DETAILS_FAILURE:
      return {
        ...state,
        isemployeedetail: false,
        error: action.error,
      };
    case ActionTypes.UPDATE_EMPLOYEE_DETAILS_REQUEST:
      return {
        ...state,
        isupdate: true,
      };
    case ActionTypes.UPDATE_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        employeeupdatedata: action.data,
        isupdate: false,
      };
    case ActionTypes.UPDATE_EMPLOYEE_DETAILS_FAILURE:
      return {
        ...state,
        isupdate: false,
        error: action.error,
      };
    case ActionTypes.UPDATE_EMPLOYEE_STATUS_REQUEST:
      return {
        ...state,
        isupdatestatus: true,
      };
    case ActionTypes.UPDATE_EMPLOYEE_STATUS_SUCCESS:
      return {
        ...state,
        employeeStatus: action.data,
        isupdatestatus: false,
      };
    case ActionTypes.UPDATE_EMPLOYEE_STATUS_FAILURE:
      return {
        ...state,
        isupdatestatus: false,
        errors: action.error,
      };
    default:
      return state;
  }
}

export default employeeSignupReducer;