import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { validationRegex } from '../../utils/conts';
import { EMPLOYEE } from '../../global/routes';
import * as RegistrationActions from './registerActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import { pwdTooltipContent } from '../../utils/helpers';
import Breadcrumb from '../breadcrumbs/index';
import classNames from 'classnames';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        20 Nov 2024
 * @description Employee Registration Form.
 * @param
 * @response 
**/

const EmployeeRegister = (props) => {
  const { register, handleSubmit, resetField, getValues, formState: { errors }, } = useForm();
  const { employeeSignup, actions } = props;
  const dispatch = useDispatch();

  const breadcrumbs = [
    { name: 'Employee', link: EMPLOYEE },
    { name: 'Employee Registration', link: "" },
  ];

  useEffect(() => {
    if (employeeSignup?.data !== undefined && employeeSignup?.data?.status === 'success' && !employeeSignup.isregistration) {
      notification(employeeSignup?.data?.message, 'success');
      dispatch({ type: RegistrationActions.ActionTypes.REGISTRATION_SUCCESS, data: undefined });
      resetField('user_first_name');
      resetField('user_last_name');
      resetField('user_email');
      resetField('password');
      resetField('user_phone_number');
      resetField('user_aadhar_card');
      resetField('user_pan_card');
      resetField('password_confirmation');
      resetField('employee_type');
    }
    if (employeeSignup?.data === undefined && employeeSignup?.error !== undefined) {
      notification(employeeSignup?.error?.message, 'error');
      dispatch({ type: RegistrationActions.ActionTypes.REGISTRATION_FAILURE, error: undefined });
    }
  }, [employeeSignup?.data, employeeSignup?.isregistration, employeeSignup?.error]);

  const onSubmit = (data) => {
    const password = getValues('password');
    const password_confirmation = getValues('password_confirmation');

    if (password !== password_confirmation) {
      notification('Password and Confirm-Password must be same.', 'error');
    } else {
      actions.registrationRequest(data);
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);
    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [])

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="card-header text-bg-primary mb-3 mt-2">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Employee Registration</h3>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("user_first_name", {
                required: 'First Name is required.',
                validate: {
                  hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name cannot contain numbers.",
                  hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name cannot contain special characters.",
                },
                minLength: {
                  value: 2,
                  message: 'First Name too short.',
                },
                maxLength: {
                  value: 50,
                  message: 'First Name too long.',
                },
              })}
              className="form-control"
              placeholder="First Name"
            />
            {errors?.user_first_name?.type === 'required' && <span className={styles.errormsg}>{errors.user_first_name.message}</span>}
            {errors?.user_first_name?.type === 'hasNoNumbers' && (<span className={styles.errormsg}>{errors.user_first_name.message}</span>)}
            {errors?.user_first_name?.type === 'hasNoSpecialChars' && (<span className={styles.errormsg}>{errors.user_first_name.message}</span>)}
            {errors?.user_first_name?.type === 'minLength' && (<span className={styles.errormsg}>{errors.user_first_name.message}</span>)}
            {errors?.user_first_name?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.user_first_name.message}</span>)}
            <label>First Name</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("user_last_name", {
                required: 'Last Name is required.',
                validate: {
                  hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name cannot contain numbers.",
                  hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name cannot contain special characters.",
                },
                minLength: {
                  value: 2,
                  message: 'Last Name too short.',
                },
                maxLength: {
                  value: 50,
                  message: 'Last Name too long.',
                },
              })}
              className="form-control"
              placeholder="Last Name"
            />
            {errors?.user_last_name?.type === 'required' && <span className={styles.errormsg}>{errors.user_last_name.message}</span>}
            {errors?.user_last_name?.type === 'hasNoNumbers' && <span className={styles.errormsg}>{errors.user_last_name.message}</span>}
            {errors?.user_last_name?.type === 'hasNoSpecialChars' && <span className={styles.errormsg}>{errors.user_last_name.message}</span>}
            {errors?.user_last_name?.type === 'minLength' && <span className={styles.errormsg}>{errors.user_last_name.message}</span>}
            {errors?.user_last_name?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.user_last_name.message}</span>)}
            <label>Last Name</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="email"
              {...register("user_email", {
                required: 'Email Address is required.',
                pattern: {
                  value: validationRegex.email,
                  message: 'Please Enter a valid Email Address.',
                },
              })}
              className="form-control"
              placeholder="Email Address"
            />
            {errors?.user_email?.type === 'required' && <span className={styles.errormsg}>{errors.user_email.message}</span>}
            {errors?.user_email?.type === 'pattern' && <span className={styles.errormsg}>{errors.user_email.message}</span>}
            <label>Email Address</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("user_phone_number", {
                required: 'Phone Number is required.',
                validate: {
                  hasTenDigits: (value) => value.length === 10 || "Phone number must be 10 digits.",
                  isValidNumber: (value) => validationRegex.phoneNum.test(value) || "Invalid phone number.",
                },
              })}
              className="form-control"
              placeholder="Phone Number"
            />
            {errors?.user_phone_number?.type === 'required' && <span className={styles.errormsg}>{errors.user_phone_number.message}</span>}
            {errors?.user_phone_number?.type === 'hasTenDigits' && <span className={styles.errormsg}>{errors.user_phone_number.message}</span>}
            {errors?.user_phone_number?.type === 'isValidNumber' && <span className={styles.errormsg}>{errors.user_phone_number.message}</span>}
            <label>Phone Number</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3 position-relative">
            <input
              type="password"
              {...register("password", {
                required: 'Password is required.',
                validate: {
                  minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                  maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                  complexity: (value) => validationRegex.password.test(value) || 'Password must be 8-16 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.',
                  noCommonPasswords: (value) =>
                    !["password", "12345", "12345678"].includes(value.toLowerCase()) || 'Weak password, avoid common passwords like "password" or "12345".',
                },
              })}
              className="form-control"
              placeholder="Password"
            />
            {errors?.password?.type === 'required' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
            {errors?.password?.type === 'minLength' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
            {errors?.password?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
            {errors?.password?.type === 'complexity' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
            {errors?.password?.type === 'noCommonPasswords' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
            <i
              className={classNames([
                'ti ti-info-circle',
                {
                  [styles.tooltipStyle]: errors?.password
                },
              ])}
              style={{
                position: "absolute",
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: '#000000',
                fontSize: 'large'
              }}
              data-tooltip-id="pwd-validate-tooltip"
            />
            <ReactTooltip
              id="pwd-validate-tooltip"
              place="right"
              content={pwdTooltipContent}
              style={{ position: 'absolute', zIndex: 9999 }}
            />
            <label>Password</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3 position-relative">
            <input
              type="password"
              {...register("password_confirmation", {
                required: 'Confirmation Password is required.',
                validate: {
                  minLength: (value) => value.length >= 8 || 'Confirmation Password should be at least 8 characters.',
                  maxLength: (value) => value.length <= 16 || 'Confirmation Password should be at most 16 characters.',
                  complexity: (value) => validationRegex.password.test(value) || 'Password must be 8-16 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.',
                  noCommonPasswords: (value) =>
                    !["password", "12345", "12345678"].includes(value.toLowerCase()) || 'Weak password, avoid common passwords like "password" or "12345".',
                },
              })}
              className="form-control"
              placeholder="Confirmation Password"
            />
            {errors?.password_confirmation?.type === 'required' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
            {errors?.password_confirmation?.type === 'minLength' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
            {errors?.password_confirmation?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
            {errors?.password_confirmation?.type === 'complexity' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
            {errors?.password_confirmation?.type === 'noCommonPasswords' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
            <i
              className={classNames([
                'ti ti-info-circle',
                {
                  [styles.tooltipStyle]: errors?.password_confirmation
                },
              ])}
              style={{
                position: "absolute",
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: '#000000',
                fontSize: 'large'
              }}
              data-tooltip-id="pwd-validate-tooltip"
            />
            <ReactTooltip
              id="pwd-validate-tooltip"
              place="right"
              content={pwdTooltipContent}
              style={{ position: 'absolute', zIndex: 9999 }}
            />
            <label>Confirm Password</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("user_aadhar_card", {
                minLength: {
                  value: 12,
                  message: 'Aadhar number must be exactly 12 digits',
                },
                maxLength: {
                  value: 12,
                  message: 'Aadhar number must be exactly 12 digits',
                },
                pattern: {
                  value: validationRegex.aadharNum, // Pattern for exactly 12 numeric digits
                  message: 'Aadhar number must be numeric and exactly 12 digits.',
                },
              })}
              className="form-control"
              placeholder="Aadhar Number"
            />
            {errors?.user_aadhar_card?.type === 'minLength' && <span className="text-danger">{errors.user_aadhar_card.message}</span>}
            {errors?.user_aadhar_card?.type === 'maxLength' && <span className="text-danger">{errors.user_aadhar_card.message}</span>}
            {errors?.user_aadhar_card?.type === 'pattern' && <span className="text-danger">{errors.user_aadhar_card.message}</span>}
            <label htmlFor="tb-pwd">Aadhar Number</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("user_pan_card", {
                pattern: {
                  value: validationRegex.panCardNum, // PAN Card pattern
                  message: 'Invalid PAN Card format. Must be 5 letters, 4 digits, followed by 1 letter.',
                },
              })}
              className="form-control"
              placeholder="PAN Card"
            />
            {errors?.user_pan_card?.type === 'pattern' && <span className={styles.errormsg}>{errors.user_pan_card.message}</span>}
            <label htmlFor="tb-cpwd">PAN Card</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select
              {...register("employee_type", {
                required: 'Employee Type is required.',
              })}
              className="form-select"
            >
              <option selected value="">Select Type</option>
              <option value="salary">Salary</option>
              <option value="target">Target</option>
            </select>
            {errors?.employee_type?.type === 'required' && <span className={styles.errormsg}>{errors.employee_type.message}</span>}
            <label>Select Employee Type</label>
          </div>
        </div>
        <div className="col-12">
          <div className="d-md-flex align-items-center">
            <div className="ms-auto mt-3 mt-md-0">
              <button
                className="btn btn-primary hstack gap-6"
                onClick={handleSubmit(onSubmit)}
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  )
}
const mapStateToProps = (state) => ({
  employeeSignup: state.employeeSignup,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(RegistrationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRegister);