import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './withdrawalActions';
import { postRequestAPI, postFileRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        12 Nov 2024
 * @description Withdrawal Saga and handling API response.
 * @param       
 * @response    Withdrawal is success or fail.  
**/

function* withdrawalSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/get-withdrawal-requests', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_WITHDRAWAL_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_WITHDRAWAL_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_WITHDRAWAL_FAILURE, error: err?.response?.data });
  }
}

function* approvewithdrawalSaga(action) {
  try {
    const response = yield call(postFileRequestAPI, { url: '', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.APPROVE_WITHDRAWAL_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.APPROVE_WITHDRAWAL_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.APPROVE_WITHDRAWAL_FAILURE, error: err?.response?.data });
  }
}

function* watchWithdrawalquest() {
  yield takeLatest(ActionTypes.GET_WITHDRAWAL_REQUEST, withdrawalSaga);
  yield takeLatest(ActionTypes.APPROVE_WITHDRAWAL_REQUEST, approvewithdrawalSaga);
}

function* WithdrawalSaga() {
  yield fork(watchWithdrawalquest);
}

export default WithdrawalSaga;