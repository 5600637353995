import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PlanCreationActions from './planCreationActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import Discount from './discount';
import WithdrawalPercentage from './withdrawalPercentage';

import styles from './styles.module.scss';

const PlanCreation = (props) => {
  const { plancreation, actions } = props;
  const dispatch = useDispatch();
  const [websites, setWebsites] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [firstInit, setFirstInit] = useState(false);

  useEffect(() => {
    if (firstInit) {
      actions.getwebsiteRequest();
      actions.getreviewsitediscountRequest();
      actions.getcurrenciesRequest();
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (plancreation?.website?.status === 'success') {
      setWebsites(plancreation?.website?.data);
    }
  }, [plancreation?.website]);

  useEffect(() => {
    if (plancreation?.currencies?.message === 'success') {
      setCurrency(plancreation?.currencies?.data);
    }
  }, [plancreation?.currencies]);

  const {
    handleSubmit: handleSubmitBasic,
    setValue,
    watch,
    register: registerBasic,
    resetField,
    reset: resetBasic,
    formState: { errors: errorsBasic },
    clearErrors,
    getValues: getValuesBasic
  } = useForm({
    defaultValues: {
      review_site_id: '',
      currency_id: '',
      review_site_price: "",
      user_earnings: "",
      teamleader_commission: "",
      employee_earnings: "",
    },
    mode: 'onChange'
  });

  const selectedSiteId = watch("review_site_id");
  const currencyId = getValuesBasic("currency_id");

  useEffect(() => {
    // Define default values
    const defaultValues = {
      review_site_price: "",
      teamleader_commission: "",
      user_earnings: "",
      employee_earnings: "",
    };

    // Store whether any values are found for each role
    const hasValue = {
      review_site_price: false,
      teamleader_commission: false,
      user_earnings: false,
      employee_earnings: false,
    };

    if (selectedSiteId && currencyId && plancreation?.siteCurrencyAmount?.length) {
      plancreation.siteCurrencyAmount.forEach((item) => {
        if (
          item.id !== null &&
          item.per_review_price_tbl_review_site_id == selectedSiteId &&
          item.per_review_price_tbl_currency_id == currencyId
        ) {
          const role = parseInt(item.per_review_price_tbl_role_id, 10);

          switch (role) {
            case 1: // User
              setValue("user_earnings", item.per_review_price_tbl_amount);
              hasValue.user_earnings = true;
              break;
            case 2: // Team Leader
              setValue("teamleader_commission", item.per_review_price_tbl_amount);
              hasValue.teamleader_commission = true;
              break;
            case 3: // Company
              setValue("review_site_price", item.per_review_price_tbl_amount);
              hasValue.review_site_price = true;
              break;
            case 4: // Employee
              setValue("employee_earnings", item.per_review_price_tbl_amount);
              hasValue.employee_earnings = true;
              break;
            default:
              break;
          }
        }
      });

      // Reset fields to default values if any role data is missing
      Object.keys(defaultValues).forEach((key) => {
        if (!hasValue[key]) {
          resetField(key);
        }
      });
    } else {
      // Reset all fields if site or currency is missing
      Object.keys(defaultValues).forEach((key) => {
        resetField(key);
      });
    }
  }, [currencyId, plancreation?.siteCurrencyAmount, selectedSiteId]);

  useEffect(() => {
    if (plancreation?.data?.status === 'success') {
      notification(plancreation?.data?.message, 'success');
      actions.getreviewsitediscountRequest();
      dispatch({ type: PlanCreationActions.ActionTypes.PLAN_CREATION_SUCCESS, data: undefined });
    }
    if (plancreation?.reviewSitePriceData?.status === 'success') {
      notification(plancreation?.reviewSitePriceData?.message, 'success');
      resetField('review_site_id');
      resetField('review_site_price');
      resetField('currency_id');
      resetField('user_earnings');
      resetField('teamleader_commission');
      resetField('employee_earnings');
      actions.getwebsiteRequest();
      actions.getcurrenciesRequest();
      dispatch({ type: PlanCreationActions.ActionTypes.UPDATE_REVIEW_SITE_PRICE_SUCCESS, data: undefined });
    }
    if (plancreation?.reviewSiteDiscount?.status === 'success') {
      notification(plancreation?.reviewSiteDiscount?.message, 'success');
      actions.getreviewsitediscountRequest();
      dispatch({ type: PlanCreationActions.ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_SUCCESS, data: undefined });
    }
    if (plancreation?.error) {
      notification(plancreation?.error?.message, 'error');
      dispatch({ type: PlanCreationActions.ActionTypes.PLAN_CREATION_FAILURE, error: undefined });
    }
  }, [plancreation?.data, plancreation?.reviewSitePriceData, plancreation?.error, plancreation?.reviewSiteDiscount]);

  const onSubmitBasic = (data) => {
    const payload = {
      review_site_price: parseFloat(data.review_site_price),
      review_site_id: parseInt(data.review_site_id),
      currency_id: parseInt(data.currency_id),
      user_earnings: parseFloat(data.user_earnings),
      teamleader_commission: parseFloat(data.teamleader_commission),
      employee_earnings: parseFloat(data.employee_earnings),
    };
    actions.updatereviewsitepriceRequest(payload);
  };

  const getAllAmounts = (e) => {
    // set currency value
    setValue("currency_id", e.target.value);

    // get all amount based on selected site and currency
    actions.getSiteCurrencyAmountRequest({
      review_site_id: selectedSiteId,
      currency_id: e.target.value
    });
  };

  return (
    <>
      <form onSubmit={handleSubmitBasic(onSubmitBasic)}>
        {/* Basic Info Section */}
        <div className="card-header text-bg-primary mb-3">
          <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Plan Creation</h3>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <div
                className="input-group"
                style={{ height: "calc(3.5rem + 2px)" }}
              >
                <span
                  className="input-group-text fw-normal text-dark fs-3"
                  style={{ minWidth: "50px", border: '1px solid #DFE5EF' }}
                >
                  Review Websites
                </span>
                <select
                  className="form-select fs-3 text-muted"
                  {...registerBasic("review_site_id", {
                    required: 'Website is required.'
                  })}
                >
                  <option value="">Select Website</option>
                  {websites?.map((site) => (
                    <option key={site?.id} value={site?.id}>
                      {site?.review_site_tbl_name}
                    </option>
                  ))}
                </select>
              </div>
              {errorsBasic?.review_site_id && <span className={styles.errormsg}>{errorsBasic.review_site_id.message}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <div
                className="input-group"
                style={{ height: "calc(3.5rem + 2px)" }}
                onClick={() => {
                  if (!selectedSiteId) {
                    notification("Please select a review site before choosing a currency.", "warning");
                  }
                }}
              >
                <span
                  className="input-group-text fw-normal text-dark fs-3"
                  style={{ minWidth: "50px", border: '1px solid #DFE5EF' }}
                >
                  {Array.isArray(currency) &&
                    (currency.find((cur) => cur.id === Number(watch("currency_id")))?.currency_symbol || 'Currency')}
                </span>
                <select
                  className="form-select fs-3 text-muted"
                  style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                  {...registerBasic("currency_id", {
                    required: "Currency is required."
                  })}
                  onChange={(e) => getAllAmounts(e)}
                >
                  <option value="">Select Currency</option>
                  {selectedSiteId && currency?.map((currency) => (
                    <option key={currency?.id} value={currency?.id}>
                      {currency?.currency_name}
                    </option>
                  ))}
                </select>
              </div>
              {errorsBasic?.currency_id && (
                <span className={styles.errormsg}>
                  {errorsBasic.currency_id.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-2">
            <div
              className="input-group"
              style={{ height: "calc(3.5rem + 2px)" }}
            >
              <span
                className="input-group-text fw-normal text-dark fs-3"
                style={{ minWidth: "50px", border: '1px solid #DFE5EF' }}
              >
                {Array.isArray(currency) &&
                  (currency.find((cur) => cur.id === Number(watch("currency_id")))?.currency_symbol || '-')}
              </span>
              <div className="form-floating">
                <input
                  type="number"
                  id="review_site_price"
                  className="form-control"
                  placeholder="Price Per Review"
                  {...registerBasic("review_site_price", {
                    required: 'Price Per Review is required.',
                    validate: {
                      positive: value => parseFloat(value) > 0 || 'Price Per Review must be greater than zero.',
                      maxLimit: value => parseFloat(value) < 1000000 || 'Price Per Review must be less than 1,000,000.'
                    }
                  })}
                  min={0}
                  max={1000000}
                />
                <label htmlFor="review_site_price">Price Per Review</label>
              </div>
            </div>
            {errorsBasic?.review_site_price && <span className={styles.errormsg}>{errorsBasic.review_site_price.message}</span>}
          </div>
          <div className="col-md-3 mb-2">
            <div
              className="input-group"
              style={{ height: "calc(3.5rem + 2px)" }}
            >
              <span
                className="input-group-text fw-normal text-dark fs-3"
                style={{ minWidth: "50px", border: '1px solid #DFE5EF' }}
              >
                {Array.isArray(currency) &&
                  (currency.find((cur) => cur.id === Number(watch("currency_id")))?.currency_symbol || '-')}
              </span>
              <div className="form-floating">
                <input
                  type="number"
                  id="user_earnings"
                  className="form-control"
                  placeholder="User Earnings"
                  {...registerBasic("user_earnings", {
                    required: 'User Earnings is required.',
                    min: {
                      value: 1,
                      message: 'User Earnings must be greater than zero.'
                    },
                    max: {
                      value: 1000000,
                      message: 'User Earnings must be less than 1,000,000.'
                    }
                  })}
                  min={0}
                  max={1000000}
                />
                <label htmlFor="user_earnings">User Earnings</label>
              </div>
            </div>
            {errorsBasic?.user_earnings && <span className={styles.errormsg}>{errorsBasic.user_earnings.message}</span>}
          </div>
          <div className="col-md-3 mb-2">
            <div
              className="input-group"
              style={{ height: "calc(3.5rem + 2px)" }}
            >
              <span
                className="input-group-text fw-normal text-dark fs-3"
                style={{ minWidth: "50px", border: '1px solid #DFE5EF' }}
              >
                {Array.isArray(currency) &&
                  (currency.find((cur) => cur.id === Number(watch("currency_id")))?.currency_symbol || '-')}
              </span>
              <div className="form-floating">
                <input
                  type="number"
                  id="teamleader_commission"
                  className="form-control"
                  placeholder="Team Leader Commision"
                  {...registerBasic("teamleader_commission", {
                    required: 'Team Leader Commision is required.',
                    min: {
                      value: 1,
                      message: 'Team Leader Commision must be greater than zero.'
                    },
                    max: {
                      value: 1000000,
                      message: 'Team Leader Commision must be less than 1,000,000.'
                    }
                  })}
                  min={0}
                  max={1000000}
                />
                <label htmlFor="teamleader_commission">Team Leader Commision</label>
              </div>
            </div>
            {errorsBasic?.teamleader_commission && (
              <span className={styles.errormsg}>{errorsBasic.teamleader_commission.message}</span>
            )}
          </div>
          <div className="col-md-3 mb-2">
            <div
              className="input-group"
              style={{ height: "calc(3.5rem + 2px)" }}
            >
              <span
                className="input-group-text fw-normal text-dark fs-3"
                style={{ minWidth: "50px", border: '1px solid #DFE5EF' }}
              >
                {Array.isArray(currency) &&
                  (currency.find((cur) => cur.id === Number(watch("currency_id")))?.currency_symbol || '-')}
              </span>
              <div className="form-floating">
                <input
                  type="number"
                  id="employee_earnings"
                  className="form-control"
                  placeholder="Employee Earnings"
                  {...registerBasic("employee_earnings", {
                    required: 'Employee Earnings is required.',
                    min: {
                      value: 1,
                      message: 'Employee Earnings must be greater than zero.'
                    },
                    max: {
                      value: 1000000,
                      message: 'Employee Earnings must be less than 1,000,000.'
                    }
                  })}
                  min={0}
                  max={1000000}
                />
                <label htmlFor="employee_earnings">Employee Earnings</label>
              </div>
            </div>
            {errorsBasic?.employee_earnings && (
              <span className={styles.errormsg}>{errorsBasic.employee_earnings.message}</span>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="d-md-flex align-items-center mt-3">
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary hstack gap-6"
                onClick={handleSubmitBasic(onSubmitBasic)}
                style={{ height: "45px" }}
                disabled={plancreation?.isupdatereviewsite}
              >
                {plancreation?.isupdatereviewsite ? <div className='spinner-border spinner-border-sm'></div> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </form >

      {/* Withdrawal Percentage */}
      <WithdrawalPercentage
        propState={plancreation}
        styles={styles}
      />

      {/* Discount Section */}
      <Discount
        propState={plancreation}
        styles={styles}
      />
      <NotificationContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  plancreation: state.plancreation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PlanCreationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanCreation);
