import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getWithdrawalPercentRequest, setWithdrawalPercentRequest } from "./planCreationActions";

const WithdrawalPercentage = ({ styles, propState }) => {
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      withdraw_percent: propState?.withdrawalPercent || ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    dispatch(getWithdrawalPercentRequest());
  }, []);

  useEffect(() => {
    if (propState?.withdrawalPercent) {
      setValue("withdraw_percent", propState?.withdrawalPercent || 0);
    }
  }, [propState?.withdrawalPercent]);

  const onSubmit = (data) => {
    dispatch(setWithdrawalPercentRequest({ min_target_complete_percent: parseFloat(data.withdraw_percent).toFixed(2) }));
  };

  return (
    <>
      <div className="card-header text-bg-primary my-3">
        <h3 className="mb-0 text-white" style={{ fontSize: "18px" }}>
          Withdrawal Percentage
        </h3>
      </div>
      <p className="mb-0" style={{ fontSize: "16px" }}>
        Define the percentage of target completion required for employees to withdraw their earnings.
      </p>
      <small>
        For example, entering '50' will allow employees to withdraw earnings after completing 50% of their assigned target.
      </small>
      <div className="d-flex align-items-center gap-3 mt-2">
        <div className="input-group flex-grow-1" style={{ height: "50px" }}>
          <input
            {...register("withdraw_percent", {
              required: "Withdrawal Percentage is required",
              pattern: {
                value: /^\d+(\.\d{1,3})?$/,
                message: "Percentage should be a number with up to 3 decimal places"
              },
              min: { value: 0, message: "Minimum percentage is 0" },
              max: { value: 100, message: "Maximum percentage cannot exceed 100" }
            })}
            type="number"
            id="withdrawalPercentage"
            className="form-control"
            placeholder="Enter percentage (0 - 100)"
            // defaultValue={propState?.withdrawalPercent || 0}
            // onChange={(e) => setValue("withdraw_percent", parseFloat(e.target.value))}
            min={0}
            max={100}
          />
          <span className="input-group-text">%</span>
        </div>
        <button
          type="submit"
          className="btn btn-primary flex-shrink-0"
          id="savePercentage"
          style={{ height: "45px" }}
          onClick={handleSubmit(onSubmit)}
          disabled={propState?.setPercentLoader}
        >
          {propState?.setPercentLoader ? <div className="spinner-border spinner-border-sm"></div> : "Save"}
        </button>
      </div>
      {errors?.withdraw_percent && (
        <span className={styles.errormsg}>{errors.withdraw_percent.message}</span>
      )}
    </>
  );
};

export default WithdrawalPercentage;
