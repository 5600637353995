/**
 * @author      Nandani.V.Patel
 * @date        21 Nov 2024
 * @description ActionTypes and ActionCreator Function for Team-leader Assign Target Request.
 * @param
 * @response      
**/

export const ActionTypes = {

  GET_ASSIGN_TEARGET_COMPANY_LISTING_REQUEST: 'GET_ASSIGN_TEARGET_COMPANY_LISTING_REQUEST',
  GET_ASSIGN_TEARGET_COMPANY_LISTING_SUCCESS: 'GET_ASSIGN_TEARGET_COMPANY_LISTING_SUCCESS',
  GET_ASSIGN_TEARGET_COMPANY_LISTING_FAILURE: 'GET_ASSIGN_TEARGET_COMPANY_LISTING_FAILURE',

  GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_REQUEST: 'GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_REQUEST',
  GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_SUCCESS: 'GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_SUCCESS',
  GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_FAILURE: 'GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_FAILURE',

  GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_REQUEST: 'GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_REQUEST',
  GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_SUCCESS: 'GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_SUCCESS',
  GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_FAILURE: 'GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_FAILURE',
};

export const assigntargetcompanyRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_LISTING_REQUEST,
    payload: paginationData,
  };
};

export const assigntargetcompanysiteRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITE_LISTING_REQUEST,
    payload: paginationData,
  };
};

export const assigntargetteamleaderRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_ASSIGN_TEARGET_COMPANY_SITEWISE_TEAMLEADER_REQUEST,
    payload: paginationData,
  };
};