import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './registerActions';
import { postRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        20 Nov 2024
 * @description Registration  Saga and handling API response.
 * @param       register-user Request paylod
 * @response    user registration  is success or fail.  
**/

function* registerUserSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/employee-register', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REGISTRATION_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REGISTRATION_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REGISTRATION_FAILURE, error: err?.response?.data });
  }
}

function* employeelistingSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/employees', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_EMPLOYEE_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_EMPLOYEE_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_EMPLOYEE_LISTING_FAILURE, error: err?.response?.data });
  }
}

function* employeedetailsSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: '', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_EMPLOYEE_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_EMPLOYEE_DETAILS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_EMPLOYEE_DETAILS_FAILURE, error: err?.response?.data });
  }
}

function* updateemployeedetailsSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/assign-target', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_EMPLOYEE_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.UPDATE_EMPLOYEE_DETAILS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_EMPLOYEE_DETAILS_FAILURE, error: err?.response?.data });
  }
}

function* employeeStatusUpdateSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/update-employee-status', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_EMPLOYEE_STATUS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.UPDATE_EMPLOYEE_STATUS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_EMPLOYEE_STATUS_FAILURE, error: err?.response?.data });
  }
}

function* watchRegistrationRequest() {
  yield takeLatest(ActionTypes.REGISTRATION_REQUEST, registerUserSaga);
  yield takeLatest(ActionTypes.GET_EMPLOYEE_LISTING_REQUEST, employeelistingSaga);
  yield takeLatest(ActionTypes.GET_EMPLOYEE_DETAILS_REQUEST, employeedetailsSaga);
  yield takeLatest(ActionTypes.UPDATE_EMPLOYEE_DETAILS_REQUEST, updateemployeedetailsSaga);
  yield takeLatest(ActionTypes.UPDATE_EMPLOYEE_STATUS_REQUEST, employeeStatusUpdateSaga);
}

function* employeeSignupSaga() {
  yield fork(watchRegistrationRequest);
}

export default employeeSignupSaga;