import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useDropzone } from 'react-dropzone';
import * as WithdrawalActions from '../withdrawal/withdrawalActions';

/**
 * @author      Nandani.V.Patel
 * @date        12 th Oct 2024
 * @description UI Implementation of Withdrawal Modal
 * @param
 * @response 
**/

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '200px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: 'var(----theme-blue-color)',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: 'var(--sidebar-active-bg)'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ImageModal = ({ onClose, imageSrc }) => {
  const { handleSubmit } = useForm();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const withdrawal = useSelector((state) => state?.withdrawal);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setError('');
    },
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        setError('Please upload an image');
      }
      if (rejectedFiles?.[0]?.errors?.[0]?.message) {
        setError(rejectedFiles[0].errors[0].message);
      }
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const onSubmit = (data) => {
    if (files?.length === 0) {
      setError('Please select an image');
    } else {
      console.log('Form submitted with image:', files[0]);
      const formData = new FormData();
      formData.append('approve_image', files[0]);
      dispatch(WithdrawalActions.approveWithdrawalRequest(formData));

      onClose();
    }
  }

  const thumbs = files.map(file => (
    <div key={file.name} className="thumb">
      <div
        className="thumb-inner"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
      >
        <img
          src={file.preview}
          alt={file.name}
          style={{ width: '90%', height: '50%' }}
        />
      </div>
    </div>
  ));
  return (
    <>
      <div
        className="modal d-block fade show"
        id="approveReq"
        tabIndex={-1}
        aria-labelledby="approveReqLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="approveReqLabel">
                Approve Request
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="details-form-content pt-0 pb-0 mb-0">
                <div className="form-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group" style={{ cursor: 'pointer' }}>
                        <div {...getRootProps({ style })}>
                          <input {...getInputProps()} />
                          {files?.length === 0 ? (
                            <p
                              className="mb-0 fw-normal text-muted fs-4"
                              style={{ color: '#666', fontWeight: '500', display: 'flex', justifyContent: 'center' }}
                            >
                              Upload proof of payment
                            </p>
                          ) : (
                            <>
                              {thumbs}
                            </>
                          )}
                        </div>
                      </div>
                      {error && (
                        <span style={{ color: 'red', marginTop: '2px', fontSize: '14px' }}>{error}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit(onSubmit)}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  )
}
export default ImageModal;