/**
 * @author      Nandani.V.Patel
 * @date        22 Nov 2024
 * @description ActionTypes and ActionCreator Function for Social-platform.
 * @param
 * @response      
**/

export const ActionTypes = {

  GET_SOCIAL_PLATFORM_LISTING_REQUEST: 'GET_SOCIAL_PLATFORM_LISTING_REQUEST',
  GET_SOCIAL_PLATFORM_LISTING_SUCCESS: 'GET_SOCIAL_PLATFORM_LISTING_SUCCESS',
  GET_SOCIAL_PLATFORM_LISTING_FAILURE: 'GET_SOCIAL_PLATFORM_LISTING_FAILURE',

  UPDATE_SOCIAL_PLATFORM_STATUS_REQUEST: 'UPDATE_SOCIAL_PLATFORM_STATUS_REQUEST',
  UPDATE_SOCIAL_PLATFORM_STATUS_SUCCESS: 'UPDATE_SOCIAL_PLATFORM_STATUS_SUCCESS',
  UPDATE_SOCIAL_PLATFORM_STATUS_FAILURE: 'UPDATE_SOCIAL_PLATFORM_STATUS_FAILURE',

  ADD_SOCIAL_PLATFORM_REQUEST: 'ADD_SOCIAL_PLATFORM_REQUEST',
  ADD_SOCIAL_PLATFORM_SUCCESS: 'ADD_SOCIAL_PLATFORM_SUCCESS',
  ADD_SOCIAL_PLATFORM_FAILURE: 'ADD_SOCIAL_PLATFORM_FAILURE',

};

export const socialplatformlistingRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_SOCIAL_PLATFORM_LISTING_REQUEST,
    payload: paginationData,
  };
};

export const updateSocialPlatformStatusRequest = (siteid) => {
  return {
    type: ActionTypes.UPDATE_SOCIAL_PLATFORM_STATUS_REQUEST,
    payload: siteid,
  };
};

export const addSocialPlatformRequest = (data) => {
  return {
    type: ActionTypes.ADD_SOCIAL_PLATFORM_REQUEST,
    payload: data,
  };
};